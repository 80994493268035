import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import "../Searchitem/Searchitem.css";
import { Modal, Button } from "react-bootstrap";
import list from "../../Assests/item.png";
import { useNavigate } from "react-router-dom";
import {
  faClock,
  faDollarSign,
  faCubes,
  faTruck,
  faCar,
  faPeopleCarry,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

const Searchitem = () => {
  const [search, setSearch] = useState("");
  const [showAdded, setShowAdded] = useState(false);
  const [searchItem, setSearchItem] = useState(false);
  const [customItem, setCustomItem] = useState({
    name: "",
    width: "",
    height: "",
    length: "",
    unit: "cm",
  });
  const [items, setItems] = useState([
    {
      id: 1,
      category: "Beds",
      name: "King bed (base or frame only)",
      volume: 1,
      quantity: 0,
    },
    {
      id: 2,
      category: "Beds",
      name: "Queen bed (base or frame only)",
      volume: 0.7,
      quantity: 0,
    },
    {
      id: 3,
      category: "Beds",
      name: "Double bed (base or frame only)",
      volume: 0.6,
      quantity: 0,
    },
    {
      id: 4,
      category: "Beds",
      name: "Single bed (base or frame only)",
      volume: 0.3,
      quantity: 0,
    },
    { id: 5, category: "Beds", name: "Mattress", volume: 0.7, quantity: 0 },
    {
      id: 6,
      category: "Furniture",
      name: "Hall Stand Table",
      volume: 0.28,
      quantity: 0,
    },
    {
      id: 7,
      category: "Furniture",
      name: "Bedside Table",
      volume: 0.15,
      quantity: 0,
    },
    {
      id: 8,
      category: "Furniture",
      name: "Chest of Drawers",
      volume: 0.5,
      quantity: 0,
    },
    {
      id: 9,
      category: "Seating",
      name: "Sofa (2 seater)",
      volume: 1.7,
      quantity: 0,
    },
    {
      id: 10,
      category: "Seating",
      name: "Sofa (3 seater)",
      volume: 2.2,
      quantity: 0,
    },
    {
      id: 11,
      category: "Seating",
      name: "Arm Chair (single)",
      volume: 0.48,
      quantity: 0,
    },
    {
      id: 12,
      category: "Seating",
      name: "Sofa (modular)",
      volume: 3.5,
      quantity: 0,
    },
    { id: 13, category: "Seating", name: "Chairs", volume: 0.15, quantity: 0 },
    { id: 14, category: "Seating", name: "Sofa bed", volume: 2.2, quantity: 0 },
    {
      id: 15,
      category: "Furniture",
      name: "Tallboy",
      volume: 0.5,
      quantity: 0,
    },
    { id: 16, category: "Furniture", name: "Wardrobe", volume: 1, quantity: 0 },
    {
      id: 17,
      category: "Furniture",
      name: "TV / Entertainment Unit",
      volume: 1,
      quantity: 0,
    },
    {
      id: 18,
      category: "Furniture",
      name: "Cabinet",
      volume: 0.85,
      quantity: 0,
    },
    {
      id: 19,
      category: "Furniture",
      name: "Bar or Crystal Cabinet",
      volume: 1,
      quantity: 0,
    },
    {
      id: 20,
      category: "Furniture",
      name: "Bookcase (small)",
      volume: 0.3,
      quantity: 0,
    },
    {
      id: 21,
      category: "Furniture",
      name: "Bookcase (medium)",
      volume: 0.6,
      quantity: 0,
    },
    {
      id: 22,
      category: "Furniture",
      name: "Bookcase (large)",
      volume: 1,
      quantity: 0,
    },
    {
      id: 23,
      category: "Furniture",
      name: "Dining Table & Chairs",
      volume: 3,
      quantity: 0,
    },
    {
      id: 24,
      category: "Furniture",
      name: "Coffee Table",
      volume: 0.28,
      quantity: 0,
    },
    {
      id: 25,
      category: "Furniture",
      name: "Dressing Table",
      volume: 1,
      quantity: 0,
    },
    { id: 26, category: "Furniture", name: "Buffet", volume: 0.8, quantity: 0 },
    { id: 27, category: "Furniture", name: "Desk", volume: 1, quantity: 0 },
    {
      id: 28,
      category: "Outdoor",
      name: "Outdoor Table & Chairs",
      volume: 1.6,
      quantity: 0,
    },
    { id: 29, category: "Electronics", name: "TV", volume: 0.4, quantity: 0 },
    {
      id: 30,
      category: "Appliances",
      name: "Dishwasher",
      volume: 0.5,
      quantity: 0,
    },
    { id: 31, category: "Appliances", name: "Freezer", volume: 1, quantity: 0 },
    {
      id: 32,
      category: "Appliances",
      name: "Fridge",
      volume: 1.5,
      quantity: 0,
    },
    {
      id: 33,
      category: "Appliances",
      name: "Tumble Dryer",
      volume: 0.5,
      quantity: 0,
    },
    {
      id: 34,
      category: "Appliances",
      name: "Washing Machine",
      volume: 0.7,
      quantity: 0,
    },
    {
      id: 35,
      category: "Boxes",
      name: "Large Box (Tea Chest)",
      volume: 0.12,
      quantity: 0,
    },
    {
      id: 36,
      category: "Boxes",
      name: "Medium Box",
      volume: 0.083,
      quantity: 0,
    },
    {
      id: 37,
      category: "Boxes",
      name: "Small Box (Book & Wine)",
      volume: 0.06,
      quantity: 0,
    },
    {
      id: 38,
      category: "Flat-Packed Furniture",
      name: "Flat-Packed Furniture (Small)",
      volume: 0.15,
      quantity: 0,
    },
    {
      id: 39,
      category: "Flat-Packed Furniture",
      name: "Flat-Packed Furniture (Medium)",
      volume: 0.3,
      quantity: 0,
    },
    {
      id: 40,
      category: "Flat-Packed Furniture",
      name: "Flat-Packed Furniture (Large)",
      volume: 0.45,
      quantity: 0,
    },
  ]);
  const [livingAreas, setLivingAreas] = useState("");
  const [furnitureVolume, setFurnitureVolume] = useState("");
  const [potPlants, setPotPlants] = useState("");
  const [outsideMove, setOutsideMove] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [error, setError] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [rateData, setRateData] = useState([]);
  const [cubicSize, setCubicSize] = useState(0);

  const [timeRequired, setTimeRequired] = useState(0);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [vehicleCount, setVehicleCount] = useState(0);
  const [vehicleTypes, setVehicleTypes] = useState("N/A");
  const [moversCount, setMoversCount] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [pickupFlights, setPickupFlight] = useState("");
  const [dropOffFlights, setDropOffFlight] = useState("");
  const [setDuration, setTotalDuration] = useState("");
  const [setBaseTime, setBaseAddressTime] = useState("");
  const [totalLoadingLow, setTotalLoadingLow] = useState(null);
  const [totalLoadingMedium, setTotalLoadingMedium] = useState(null);
  const [totalLoadingHigh, setTotalLoadingHigh] = useState(null);
  const [totalUnloadingLow, setTotalUnloadingLow] = useState(null);
  const [totalUnloadingMedium, setTotalUnloadingMedium] = useState(null);
  const [totalUnloadingHigh, setTotalUnloadingHigh] = useState(null);

  const [loadingLowTiming, setLoadingLowTiming] = useState(null);
  const [loadingMediumTiming, setLoadingMediumTiming] = useState(null);
  const [loadingHighTiming, setLoadingHighTiming] = useState(null);
  const [unLoadingLowTiming, setUnLoadingLowTiming] = useState(null);
  const [unLoadingMediumTiming, setUnLoadingMediumTiming] = useState(null);
  const [unLoadingHighTiming, setUnLoadingHighTiming] = useState(null);

  const [totalDurationCost, setTotalDurationCost] = useState(null);
  const [grandTotalHigh, setGrandTotalHigh] = useState(null);
  const [grandTotalMedium, setGrandTotalMedium] = useState(null);
  const [grandTotalLow, setGrandTotalLow] = useState(null);
  const [costPerHour, setCostPerHour] = useState(null);
  const [bookingFee, setBookingFee] = useState(null);
  const [grandTotalBackToBase, setGrandTotalBackToBase] = useState(null);
  const [requiredVehicles, setTotalVehicles] = useState(null);
  const [assembly_disassembly_per_hour_rate, setAssemblyDisassemblyRate] =
    useState(0);

  useEffect(() => {
    fetchEnquiry();
  });

  // useEffect(() => {
  //   const hasSelectedItems = items.some((item) => item.quantity > 4);
  //   setButtonDisabled(!hasSelectedItems);
  // }, [items]);

  useEffect(() => {
    const totalVolume = items.reduce(
      (sum, item) => sum + item.volume * item.quantity,
      0
    );

    const enableButton = totalVolume >= 5;
    setButtonDisabled(!enableButton);
  }, [items]);

  const fetchEnquiry = () => {
    const enquiry_id = localStorage.getItem("enquiry_id");

    fetch(`https://booking.skilledmovers.com.au/api/enquiry-data/${enquiry_id}`)
      .then((response) => response.json())
      .then((data) => {
        // Assuming data is an array with one object
        if (data.length > 0) {
          const enquiryData = data[0]; // Get the first object

          // Extract the relevant fields
          const pickupFlights = enquiryData.how_many_flights_of_stairs;
          const dropOffFlights = enquiryData.drop_of_how_many_flights_of_stairs;
          const totalDuration = enquiryData.total_duration;
          const hours = parseInt(totalDuration.split(":")[0]);
          const minutes = parseInt(totalDuration.split(":")[1]);
          const seconds = parseInt(totalDuration.split(":")[2]);

          const totalDurationInSeconds = hours * 3600 + minutes * 60 + seconds;

          const finalDuration =
            totalDurationInSeconds < 1800 ? 30 : totalDurationInSeconds / 60;
          const baseAddressTime = enquiryData.baseAddress_time;
          const formattedDuration = `${Math.floor(finalDuration / 60)}:${
            finalDuration % 60
          }:00`;
          setTotalDuration(formattedDuration);
          // Update the state with the extracted values
          setPickupFlight(pickupFlights);
          setDropOffFlight(dropOffFlights);
          // setTotalDuration(finalDuration);
          // console.log("totalDurationsndfklnasdfnasdlfnsdfnsdklfnksdfnsldn",formattedDuration)
          setBaseAddressTime(baseAddressTime);

          // Log the extracted values for debugging
          // console.log(".........pickupFlights", pickupFlights);
          // console.log("............dropOffFlights", dropOffFlights);
          // console.log("............totalDuration", totalDuration);
          // console.log("............baseAddressTime", baseAddressTime);
        } else {
          console.log("No enquiry data found.");
        }
      })
      .catch((error) => {
        console.error("Error fetching enquiry data:", error);
      });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomItem({
      ...customItem,
      [name]: value,
    });
  };

  const handleIncrement = (id) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (id) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id && item.quantity > 0
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  const displayedItems = showAdded
    ? filteredItems.filter((item) => item.quantity > 0)
    : filteredItems;

  const categories = [...new Set(items.map((item) => item.category))];

  const navigate = useNavigate();

  const handlebackNavigate = () => {
    navigate("/movinglocation");
  };

  const handlenext = () => {
    navigate("/movefrom");
  };

  const handlePostData = async () => {
    // Calculate total volume of selected items
    const totalVolume = items.reduce(
      (sum, item) => sum + item.volume * item.quantity,
      0
    );

    // Calculate required vehicles based on total volume
    const requiredVehicles = Math.ceil(totalVolume / 10);

    // Generate the items array with item_name, quantity, and volume
    const itemsToPost = items
      .filter((item) => item.quantity > 0) // Filter only the selected items
      .map((item) => ({
        name: item.name, // Matching the API request data structure
        quantity: item.quantity,
        volume: item.volume, // Volume of each item
      }));

    // Prepare the data to post
    const dataToPost = {
      enquiry_id: localStorage.getItem("enquiry_id"),
      items: itemsToPost,
      totalVolume: totalVolume,
      requiredVehicles: requiredVehicles, // Keep this one
      moversCount: moversCount,
      estimatedTime: estimatedTime,
      totalCost: totalCost, // Consistent with API response
      totalLoadingLow: totalLoadingLow,
      totalLoadingMedium: totalLoadingMedium,
      totalLoadingHigh: totalLoadingHigh,
      totalUnloadingLow: totalUnloadingLow,
      totalUnloadingMedium: totalUnloadingMedium,
      totalUnloadingHigh: totalUnloadingHigh,
      loadingLowTiming: loadingLowTiming,
      loadingMediumTiming: loadingMediumTiming,
      loadingHighTiming: loadingHighTiming,
      unLoadingLowTiming: unLoadingLowTiming,
      unLoadingMediumTiming: unLoadingMediumTiming,
      unLoadingHighTiming: unLoadingHighTiming,
      totalDurationCost: totalDurationCost,
      grandTotalHigh: grandTotalHigh,
      grandTotalMedium: grandTotalMedium,
      grandTotalLow: grandTotalLow,
      costPerHour: costPerHour,
      bookingfee: bookingFee,
      totalDurationCostBackToBase: grandTotalBackToBase,
      assembly_disassembly_per_hour_rate,
    };

    // console.log("Data to post:", dataToPost);

    try {
      const response = await axios.post(
        "https://booking.skilledmovers.com.au/api/save-handel-items",
        dataToPost
      );
      // console.log('Response:', response.data);
      if (
        response.data &&
        response.data.message === "Data saved successfully!"
      ) {
        // Navigate to the next screen
        navigate("/movefrom");
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  useEffect(() => {
    calculateTotals(); // Recalculate totals whenever items change
  }, [items]);

  // Calculate totals
  const calculateTotals = () => {
    const totalSize = items.reduce(
      (sum, item) => sum + item.volume * item.quantity,
      0
    );
    setVehicleCount(Math.ceil(totalSize / 10));
    setMoversCount(Math.ceil(totalSize / 20));
    setEstimatedTime(Math.ceil(totalSize / 5));
    setTotalCost(totalSize * 10);
  };

  const calculateTotalVehicles = () => {
    const { requiredVehicles } = getRequiredVehicles(cubicSize);
    setTotalVehicles(requiredVehicles);
  };
  useEffect(() => {
    calculateTotalVehicles();
  }, [cubicSize]);
  const getRequiredVehicles = (cubicSize) => {
    let remainingVolume = cubicSize;
    let truckNames = [];
    let totalVehicles = 0;

    for (const vehicle of vehicles) {
      if (remainingVolume <= 0) break;

      const truckSize = vehicle.size;
      if (remainingVolume <= truckSize) {
        truckNames.push(`${vehicle.name} (x1)`);
        totalVehicles += 1;
        remainingVolume = 0;
        break;
      }
    }

    if (remainingVolume > 0) {
      for (const vehicle of vehicles.reverse()) {
        if (remainingVolume <= 0) break;

        const truckSize = vehicle.size;
        const count = Math.ceil(remainingVolume / truckSize);
        remainingVolume -= count * truckSize;
        totalVehicles += count;
        truckNames.push(`${vehicle.name} (x${count})`);
      }
    }

    return { requiredVehicles: totalVehicles, truckNames };
  };

  useEffect(() => {
    // Fetch vehicle sizes and rate data on component mount
    const fetchData = async () => {
      try {
        const vehicleResponse = await fetch(
          "https://booking.skilledmovers.com.au/api/vehicle-size"
        );
        const vehicleData = await vehicleResponse.json();
        setVehicles(vehicleData);

        const rateResponse = await fetch(
          "https://booking.skilledmovers.com.au/api/rate-data"
        );
        const rateData = await rateResponse.json();
        setRateData(
          rateData.map((rate) => ({
            cubic_meter_size_start: parseFloat(rate.cubic_meter_size_start),
            cubic_meter_size_end: parseFloat(rate.cubic_meter_size_end),
            time_required: parseFloat(rate.time_required),
            movers_needed: parseInt(rate.movers_needed),
            per_hour_rate: parseFloat(rate.per_hour_rate),
            loading_high_time: rate.loading_high_time,
            loading_medium_time: rate.loading_medium_time,
            loading_low_time: rate.loading_low_time,
            unloading_high_time: rate.unloading_high_time,
            unloading_medium_time: rate.unloading_medium_time,
            unloading_low_time: rate.unloading_low_time,
            booking_fee: rate.booking_fee,
            loading_oneflight: rate.loading_oneflight,
            loading_secondflight: rate.loading_secondflight,
            loading_thirdflight: rate.loading_thirdflight,
            unloading_oneflight: rate.unloading_oneflight,
            unloading_secondflight: rate.unloading_secondflight,
            unloading_thirdflight: rate.unloading_thirdflight,
            assembly_disassembly_per_hour_rate: parseFloat(
              rate.assembly_disassembly_per_hour_rate
            ),
          }))
        );
        if (rateData.length > 0) {
          setAssemblyDisassemblyRate(
            parseFloat(rateData[0].assembly_disassembly_per_hour_rate)
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const calculateEstimate = () => {
      //       console.log("calculateEstimate is running");
      //       console.log("Incoming rateData:", rateData);
      //       console.log("Bedrooms:", bedrooms);
      // console.log("Living Areas:", livingAreas);
      // console.log("Furniture Volume:", furnitureVolume);
      // console.log("Outside Move:", outsideMove);
      // console.log("Pot Plants:", potPlants);

      let newCubicSize = 0;
      const totalVolume = items.reduce(
        (sum, item) => sum + item.volume * item.quantity,
        0
      );
      // console.log("newCubicSize totalVolume", totalVolume)
      newCubicSize = totalVolume; // Set newCubicSize to totalVolume

      setCubicSize(newCubicSize);

      // console.log("Cubic Size:", newCubicSize);

      // Determine vehicle count
      let newVehicleCount = 0;
      let vehicleSize = "Unknown";
      for (let vehicle of vehicles) {
        if (vehicle.vehicle_size >= newCubicSize) {
          vehicleSize = vehicle.vehicle_type;
          newVehicleCount = 1;
          break;
        }
      }

      if (vehicleSize === "Unknown") {
        for (let vehicle of vehicles) {
          if (vehicle.vehicle_size < newCubicSize) {
            vehicleSize = vehicle.vehicle_type;
            newVehicleCount = Math.ceil(newCubicSize / vehicle.vehicle_size);
            break;
          }
        }
      }

      setVehicleCount(newVehicleCount);

      // Determine movers count and total cost
      // console.log("newCubicSize:", newCubicSize, typeof newCubicSize);
      const rate = rateData.find((rate) => {
        const start = rate.cubic_meter_size_start;
        const end = rate.cubic_meter_size_end;

        console.log(`Checking if ${start} <= ${newCubicSize} <= ${end}`);
        return start <= newCubicSize && newCubicSize <= end;
      });

      if (!rate) {
        console.error("No matching rate found for cubic size:", newCubicSize);
      }
      if (rate) {
        console.error(" matching rate found for cubic size:", newCubicSize);
        const estimatedTime = timeStringToMinutes(rate.time_required);
        const costPerHour = parseFloat(rate.per_hour_rate) || 0;
        const costPerMinute = costPerHour / 60;
        //         console.log("this function is running named rate ")
        //     console.log("Rate Data after conversion:", rate);
        //     console.log("Estimated Time in Minutes:", estimatedTime);
        //     console.log("Cost Per Hour:", costPerHour);
        //     console.log("Cost Per Minute:", costPerMinute);
        //  console.log("calculate estimate rate.loading_low_time",rate.loading_low_time)
        let loadingLowTime = timeStringToMinutes(rate.loading_low_time);
        let loadingMediumTime = timeStringToMinutes(rate.loading_medium_time);
        let loadingHighTime = timeStringToMinutes(rate.loading_high_time);
        let unLoadingLowTime = timeStringToMinutes(rate.unloading_low_time);
        let unLoadingMediumTime = timeStringToMinutes(
          rate.unloading_medium_time
        );
        let unLoadingHighTime = timeStringToMinutes(rate.unloading_high_time);

        const loadingOneFlight = timeStringToMinutes(rate.loading_oneflight);
        const loadingSecondFlight = timeStringToMinutes(
          rate.loading_secondflight
        );
        const loadingThirdFlight = timeStringToMinutes(
          rate.loading_thirdflight
        );
        const unloadingOneFlight = timeStringToMinutes(
          rate.unloading_oneflight
        );
        const unloadingSecondFlight = timeStringToMinutes(
          rate.unloading_secondflight
        );
        const unloadingThirdFlight = timeStringToMinutes(
          rate.unloading_thirdflight
        );

        const loadingStairs = pickupFlights || "";
        const unloadingStairs = dropOffFlights || "";

        if (loadingStairs) {
          if (loadingStairs.includes("1 Flight")) {
            loadingLowTime += loadingOneFlight;
            loadingMediumTime += loadingOneFlight;
            loadingHighTime += loadingOneFlight;
          } else if (loadingStairs.includes("2 Flight")) {
            loadingLowTime += loadingSecondFlight;
            loadingMediumTime += loadingSecondFlight;
            loadingHighTime += loadingSecondFlight;
          } else if (loadingStairs.includes("3+ Flight")) {
            loadingLowTime += loadingThirdFlight;
            loadingMediumTime += loadingThirdFlight;
            loadingHighTime += loadingThirdFlight;
          }
        }

        if (unloadingStairs && unloadingStairs !== "null") {
          if (unloadingStairs.includes("1 Flight")) {
            unLoadingLowTime += unloadingOneFlight;
            unLoadingMediumTime += unloadingOneFlight;
            unLoadingHighTime += unloadingOneFlight;
          } else if (unloadingStairs.includes("2 Flight")) {
            unLoadingLowTime += unloadingSecondFlight;
            unLoadingMediumTime += unloadingSecondFlight;
            unLoadingHighTime += unloadingSecondFlight;
          } else if (unloadingStairs.includes("3+ Flight")) {
            unLoadingLowTime += unloadingThirdFlight;
            unLoadingMediumTime += unloadingThirdFlight;
            unLoadingHighTime += unloadingThirdFlight;
          }
        }

        const loadingLowTiming = minutesToTimeString(loadingLowTime);
        const loadingMediumTiming = minutesToTimeString(loadingMediumTime);
        const loadingHighTiming = minutesToTimeString(loadingHighTime);
        const unLoadingLowTiming = minutesToTimeString(unLoadingLowTime);
        const unLoadingMediumTiming = minutesToTimeString(unLoadingMediumTime);
        const unLoadingHighTiming = minutesToTimeString(unLoadingHighTime);

        const totalLoadingLow = (loadingLowTime * costPerMinute).toFixed(2);
        const totalLoadingMedium = (loadingMediumTime * costPerMinute).toFixed(
          2
        );
        const totalLoadingHigh = (loadingHighTime * costPerMinute).toFixed(2);

        const totalUnloadingLow = (unLoadingLowTime * costPerMinute).toFixed(2);
        const totalUnloadingMedium = (
          unLoadingMediumTime * costPerMinute
        ).toFixed(2);
        const totalUnloadingHigh = (unLoadingHighTime * costPerMinute).toFixed(
          2
        );

        const totalMinutes = convertToMinutes(setDuration || "0");
        const totalDurationCost = (totalMinutes * costPerMinute).toFixed(2);

        const bookingFee = parseFloat(rate.booking_fee) || 0;

        let totalBackToBaseTimeMinutes = convertToMinutes(setBaseTime || "0");
        if (totalBackToBaseTimeMinutes < 30) {
          totalBackToBaseTimeMinutes = 30;
        }
        const grandTotalBackToBase = (
          totalBackToBaseTimeMinutes * costPerMinute
        ).toFixed(2);
        // console.log("back to base time", totalBackToBaseTimeMinutes);

        const grandTotalLow = (
          parseFloat(totalLoadingLow) +
          parseFloat(totalUnloadingLow) +
          parseFloat(totalDurationCost) +
          parseFloat(bookingFee) +
          parseFloat(grandTotalBackToBase)
        ).toFixed(2);
        const grandTotalMedium = (
          parseFloat(totalLoadingMedium) +
          parseFloat(totalUnloadingMedium) +
          parseFloat(totalDurationCost) +
          parseFloat(bookingFee) +
          parseFloat(grandTotalBackToBase)
        ).toFixed(2);
        const grandTotalHigh = (
          parseFloat(totalLoadingHigh) +
          parseFloat(totalUnloadingHigh) +
          parseFloat(totalDurationCost) +
          parseFloat(bookingFee) +
          parseFloat(grandTotalBackToBase)
        ).toFixed(2);

        // console.log("grandBack to base", grandTotalBackToBase);
        // console.log("cost per minute", costPerMinute);
        // console.log("Booking fee", bookingFee);

        // console.log("Loading Low Timing: ", loadingLowTiming);
        // console.log("Loading Medium Timing: ", loadingMediumTiming);
        // console.log("Loading High Timing: ", loadingHighTiming);
        // console.log("Unloading Low Timing: ", unLoadingLowTiming);
        // console.log("Unloading Medium Timing: ", unLoadingMediumTiming);
        // console.log("Unloading High Timing: ", unLoadingHighTiming);
        // console.log("Total Duration Cost: ", totalDurationCost);
        // console.log("Grand Total low", grandTotalLow);
        // console.log("Grand Total Mediumn", grandTotalMedium);
        // console.log("Grand Total high", grandTotalHigh);
        setTotalLoadingLow(totalLoadingLow);
        setTotalLoadingMedium(totalLoadingMedium);
        setTotalLoadingHigh(totalLoadingHigh);
        setTotalUnloadingLow(totalUnloadingLow);
        setTotalUnloadingMedium(totalUnloadingMedium);
        setTotalUnloadingHigh(totalUnloadingHigh);

        setLoadingLowTiming(loadingLowTiming);
        setLoadingMediumTiming(loadingMediumTiming);
        setLoadingHighTiming(loadingHighTiming);
        setUnLoadingLowTiming(unLoadingLowTiming);
        setUnLoadingMediumTiming(unLoadingMediumTiming);
        setUnLoadingHighTiming(unLoadingHighTiming);

        setTotalDurationCost(totalDurationCost);
        setGrandTotalHigh(grandTotalHigh);
        setGrandTotalMedium(grandTotalMedium);
        setGrandTotalLow(grandTotalLow);
        setCostPerHour(costPerHour);
        setBookingFee(bookingFee);
        setGrandTotalBackToBase(grandTotalBackToBase);
        setMoversCount(rate.movers_needed);
        setTimeRequired(rate.time_required);
        setTotalCost((rate.time_required * rate.per_hour_rate).toFixed(2));
        return {
          estimatedTime: minutesToTimeString(estimatedTime),
          totalLoadingLow,
          totalLoadingMedium,
          totalLoadingHigh,
          totalUnloadingLow,
          totalUnloadingMedium,
          totalUnloadingHigh,
          loadingLowTiming,
          loadingMediumTiming,
          loadingHighTiming,
          unLoadingLowTiming,
          unLoadingMediumTiming,
          unLoadingHighTiming,
          totalDurationCost,
          grandTotalHigh,
          grandTotalMedium,
          grandTotalLow,
          costPerHour,
          bookingFee,
          grandTotalBackToBase,
        };
      } else {
        setMoversCount(0);
        setTotalCost(0);
      }
    };

    const convertToMinutes = (timeString) => {
      if (!timeString || typeof timeString !== "string") {
        console.warn("Invalid time format:", timeString);
        return 0;
      }

      const [hours, minutes] = timeString.split(":").map(Number);
      return hours * 60 + minutes;
    };

    const timeStringToMinutes = (timeString) => {
      if (typeof timeString === "number") {
        return timeString * 60;
      }
      if (typeof timeString === "string") {
        const [hoursStr, minutesStr] = timeString.split(":");
        const hours = parseFloat(hoursStr) || 0;
        const minutes = parseFloat(minutesStr) || 0;
        return hours * 60 + minutes;
      }
      console.warn("Invalid time format:", timeString);
      return 0;
    };

    const minutesToTimeString = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}`;
    };

    calculateEstimate();
  });

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []);

  return (
    <>
      <div className="access-properties">
        <div className="container">
          <div id="list-items" className="target-section">
            <nav
              className="nav d-flex justify-content-around"
              role="tablist"
              aria-orientation="vertical"
            >
              <a className="nav-link">Location</a>
              <a className="nav-link active">Move Size</a>
              <a className="nav-link">Date & Time</a>
              <a className="nav-link">Details</a>
              <a className="nav-link">My Quote</a>
            </nav>
            <h3 className="text-center heading__text__skilled">
              Select items or use the search function…
            </h3>
            <p style={{ textAlign: "center" }}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handlebackNavigate();
                }}
                style={{ textDecoration: "none", color: "#ff5c00" }}
              >
                Change the method for estimation.
              </a>
            </p>
            <div className="your-items-container">
              <div className="header">
                <h2 className="heading__text__skilled">Your Items</h2>
                <span className="items-added">
                  {items.reduce((total, item) => total + item.quantity, 0)}{" "}
                  items added
                </span>
              </div>
              <div className="search-container">
                <FaSearch className="search-icon" />
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search 100+ items..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <a
                className="custom-item-link"
                onClick={() => setSearchItem(true)}
              >
                Add a custom item
              </a>
              <div className="toggle-container">
                <label className="toggle-label">
                  Show only the added items
                  <input
                    type="checkbox"
                    checked={showAdded}
                    onChange={() => setShowAdded(!showAdded)}
                  />
                  <span className="toggle-slider"></span>
                </label>
              </div>
              {categories.map((category) => (
                <div key={category}>
                  <div
                    className={`category heading__text__skilled ${
                      showAdded &&
                      !displayedItems.some((item) => item.category === category)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    {category}
                  </div>
                  <ul className="items-list">
                    {displayedItems
                      .filter((item) => item.category === category)
                      .map((item) => (
                        <li key={item.id} className="item list__category">
                          <div
                            className="item-info"
                            style={{ textAlign: "left" }}
                          >
                            <span className="item-name">{item.name}</span>
                            <span className="item-volume">{item.volume}</span>
                          </div>

                          <div className="item-controls">
                            {item.quantity > 0 && (
                              <button
                                className="decrement-button room-button"
                                onClick={() => handleDecrement(item.id)}
                                style={{ border: "#ff5c00" }}
                              >
                                -
                              </button>
                            )}
                            <span className="item-count">{item.quantity}</span>
                            <button
                              className="increment-button room-button"
                              onClick={() => handleIncrement(item.id)}
                              style={{ border: "ff5c00" }}
                            >
                              +
                            </button>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}

              <Modal
                show={searchItem}
                onHide={() => setSearchItem(false)}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <img
                      src={list}
                      alt="Pick Up Logo"
                      style={{ width: "50px", marginRight: "10px" }}
                    />
                    Add Custom Item
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form className="custom-item-form">
                    <div className="form-group">
                      <label htmlFor="name">Title</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="w-100"
                        value={customItem.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group">
                        <label>Width</label>
                        <input
                          type="text"
                          name="width"
                          className="w-100"
                          value={customItem.width}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>Height</label>
                        <input
                          type="text"
                          name="height"
                          className="w-100"
                          value={customItem.height}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group">
                        <label>Length</label>
                        <input
                          type="text"
                          name="length"
                          className="w-100"
                          value={customItem.length}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>Unit</label>
                        <select
                          name="unit"
                          className="w-100"
                          value={customItem.unit}
                          onChange={handleInputChange}
                        >
                          <option value="cm">cm</option>
                          <option value="in">in</option>
                          <option value="ft">ft</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <p>Approx size: 0 m³</p>
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className="map_done"
                    onClick={() => setSearchItem(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" className="map_done">
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>
              <hr />
              <div className="d-flex align-items-center justify-content-between features__skilled__total">
                <div className="total" style={{ color: "#ff5c00" }}>
                  <FontAwesomeIcon
                    icon={faCubes}
                    style={{ marginRight: "10px", color: "#40e550" }}
                  />{" "}
                  <span id="totalSize">
                    {(() => {
                      const totalSize = items.reduce(
                        (sum, item) => sum + item.volume * item.quantity,
                        0
                      );

                      return totalSize && !isNaN(totalSize)
                        ? totalSize.toFixed(2)
                        : 0;
                    })()}
                  </span>{" "}
                  m³
                </div>
                <div className="vehicle-info" style={{ color: "#ff5c00" }}>
                  <FontAwesomeIcon
                    icon={faTruck}
                    style={{ marginRight: "10px", color: "#40e550" }}
                  />{" "}
                  <span id="vehicle-count">{vehicleCount} Truck</span>
                  {/* <br />
                  <FontAwesomeIcon icon={faCar} /> <span id="vehicle-types">{vehicleTypes}</span> */}
                </div>
                <div className="mover-info" style={{ color: "#ff5c00" }}>
                  <FontAwesomeIcon
                    icon={faPeopleCarry}
                    style={{ marginRight: "10px", color: "#40e550" }}
                  />{" "}
                  <span id="movers-count">{moversCount} Movers</span>
                </div>
                <div className="total-cost-info" style={{ color: "#ff5c00" }}>
                  <FontAwesomeIcon
                    icon={faClock}
                    style={{ marginRight: "10px", color: "#40e550" }}
                  />{" "}
                  <span id="estimated-time">{estimatedTime}</span> Hr
                  <br />
                  {/* <FontAwesomeIcon icon={faDollarSign} />
                   <span id="total-cost">{totalCost}</span> USD */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg__footerContainer">
        <div className="footer-container">
          <button
            type="button"
            className="next_link"
            onClick={handlePostData}
            disabled={buttonDisabled}
          >
            NEXT
          </button>
        </div>
      </div>
    </>
  );
};

export default Searchitem;
