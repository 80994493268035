import React, { useState, useEffect } from "react";
import "../Photos/Photo.css";
import uplode from "../../Assests/upload.png";
import axios from "axios";

const Emailphotoinventory = () => {
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const closeModal = () => setSelectedImage(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const enquiryId = localStorage.getItem("enquiry_id");

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      console.log("No file selected.");
      return;
    }

    const enquiryId = localStorage.getItem("enquiry_id");
    if (!enquiryId) {
      console.log("Enquiry ID is missing.");
      return;
    }

    const formData = new FormData();
    formData.append("images[]", file);
    formData.append("enquiry_id", enquiryId);

    try {
      const response = await fetch(
        "https://booking.skilledmovers.com.au/api/upload-image",
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      if (response.ok) {
        console.log("Image uploaded successfully:", result);
        setImages((prevImages) => [
          ...prevImages,
          { url: URL.createObjectURL(file) },
        ]);
        setFile(null);
      } else {
        console.error("Upload failed:", result.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleSave = () => {
    console.log("Images and description have been saved successfully:");
    setDescription("");
  };

  useEffect(() => {
    const fetchImages = async () => {
      if (!enquiryId) {
        console.error("Enquiry ID is missing");
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.get(
          `https://booking.skilledmovers.com.au/api/get-image/${enquiryId}`
        );
        const imageFiles = response.data.enquiry?.image;
        if (imageFiles) {
          const imageArray = imageFiles.split(",").map((filename) => ({
            url: `https://booking.skilledmovers.com.au/image/${filename}`,
          }));
          setImages(imageArray);
        } else {
          setImages([]);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (enquiryId) {
      fetchImages();
    }
  }, [enquiryId]);

  useEffect(() => {
    // Enable button only if all required fields are filled
    if (description) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [description]);

  return (
    <>
      <h3 className="text-center fs-4 heading__text__skilled">
        Feel free to upload photos, videos, or documents below.
      </h3>
      <div className="container upload-container">
        <div className="row">
          <div className="col-md-6">
            <div className="upload-section">
              <h2 className="upload-title heading__text__skilled">Upload photos</h2>
              <p className="upload-subtitle">
                For the property we're picking up from
              </p>
              <div className="photos-count">Photos ({images.length})</div>
              <div className="images-preview">
                {isLoading ? (
                  <p>Loading images...</p>
                ) : (
                  images.map((image, index) => (
                    <img
                      key={index}
                      src={image.url}
                      alt={`uploaded ${index}`}
                      className="uploaded-image"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        margin: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => setSelectedImage(image.url)}
                    />
                  ))
                )}

                {/* Large preview section */}
                {selectedImage && (
                  <div
                    className="image-preview-modal-overlay"
                    onClick={closeModal}
                    style={{
                      position: "fixed",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      className="image-preview-modal"
                      style={{
                        position: "relative",
                        maxWidth: "80%",
                        maxHeight: "80%",
                        backgroundColor: "white",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img
                        src={selectedImage}
                        alt="Selected Preview"
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "500px",
                          objectFit: "contain",
                        }}
                      />
                      <button
                        onClick={closeModal}
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          backgroundColor: "#ff5c00",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                      >
                        X
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="notes-section">
                <label className="notes-label">Notes (optional)</label>
                <textarea
                  className="notes-input"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter a description..."
                ></textarea>
                <div className="save-notes-container">
                  <button
                    className={`next_link ${buttonDisabled ? "disabled" : ""}`}
                    disabled={buttonDisabled}
                    onClick={handleSave}
                  >
                    Save notes
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 pt-3 pt-md-0">
            <div className="upload-section">
              <div className="upload-box">
                <input
                  type="file"
                  onChange={handleFileChange}
                  id="file-upload"
                  hidden
                />
                <label htmlFor="file-upload" className="upload-label">
                  <div className="upload-icon" onClick={handleUpload}>
                    <img
                      src={uplode}
                      style={{ width: "100%", height: "100%" }}
                      alt="upload icon"
                    />
                  </div>
                  <p className="upload-text" style={{ color: "#ff5c00" }}>
                    Click to upload
                  </p>
                  <p>or drag and drop</p>
                  <p>photos or video files</p>
                </label>
              </div>

              <div className="upload-help">
                Trouble uploading photos?
                <a
                  href="mailto:Info@skilledmovers.com.au"
                  style={{ color: "#ff5c00" }}
                >
                  Email them to Info@skilledmovers.com.au
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Emailphotoinventory;
