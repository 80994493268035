import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../Inventory/Inventory.css";

function QuickInvent() {
  const { enquiryId } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quoteId, setQuoteId] = useState();

  useEffect(() => {
    const enquiryId = localStorage.getItem("enquiry_id");
    setQuoteId(enquiryId);
    if (enquiryId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://booking.skilledmovers.com.au/api/quote/${enquiryId}`
          );
          console.log("API Response:", response.data); // Check API response structure

          // Extract specific fields from the 'enquiry' object
          const {
            how_many_bedroom,
            living_areas_with_couches,
            volume_of_furniture,
            move_from_outside_your_home,
            number_of_pot_plants,
          } = response.data.enquiry; // Access 'enquiry' object

          setData({
            how_many_bedroom,
            living_areas_with_couches,
            volume_of_furniture,
            move_from_outside_your_home,
            number_of_pot_plants,
          });
        } catch (error) {
          setError("Error fetching data");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else {
      setError("No quote ID found in local storage");
      setLoading(false);
    }
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      {/* Desktop View */}
      <div className="inventory-container d-none d-sm-block">
        <div className="d-flex justify-content-between ">
          <h2 style={{ color: "#ff5c00" }} className="fs-5 ">
            Bedrooms
          </h2>
          <div>
            <h2 style={{ color: "#ff5c00" }} className="fs-5 ">
              Couches
            </h2>
          </div>
          <div>
            <h2 style={{ color: "#ff5c00" }} className="fs-5 ">
              Furniture
            </h2>
          </div>
          <div>
            <h2 style={{ color: "#ff5c00" }} className="fs-5 ">
              Outer Relocation
            </h2>
          </div>
          <div>
            <h2 style={{ color: "#ff5c00" }} className="fs-5 ">
              Pot Plants
            </h2>
          </div>
        </div>
        <hr />
        <div className="inventory-details">
          {data && (
            <ul
              className="d-flex align-items-center justify-content-between fs-6"
              style={{ listStyle: "none", padding: 0 }}
            >
              <li>
                <strong></strong> {data.how_many_bedroom}
              </li>
              <li>
                <strong></strong> {data.living_areas_with_couches}
              </li>
              <li>
                <strong></strong> {data.volume_of_furniture}
              </li>
              <li>
                <strong></strong> {data.move_from_outside_your_home}
              </li>
              <li>
                <strong></strong> {data.number_of_pot_plants}
              </li>
            </ul>
          )}
        </div>
      </div>

      {/* Mobile view code */}
      <div className="inventory-container d-block d-sm-none overflow-x-auto ">

          <table className="table ">
            <thead>
              <tr style={{ color: "#ff5c00" }} className="fs-5 border-0">
                <th>Bedrooms</th>
                <th style={{backgroundColor:"transparent" , color:"black", border:"none"}}>Couches</th>
                <th style={{backgroundColor:"transparent" , color:"black", border:"none"}}>Furniture</th>
                <th style={{backgroundColor:"transparent" , color:"black", border:"none"}}>Outer Relocation</th>
                <th style={{backgroundColor:"transparent" , color:"black", border:"none"}}>Pot Plants</th>
              </tr>
            </thead>
            <tbody>
              {data && (
                <tr className="fs-6">
                  <td style={{backgroundColor:"transparent" , color:"black", border:"none"}}>{data.how_many_bedroom}</td>
                  <td style={{backgroundColor:"transparent" , color:"black", border:"none"}}>{data.living_areas_with_couches}</td>
                  <td style={{backgroundColor:"transparent" , color:"black", border:"none"}}>{data.volume_of_furniture}</td>
                  <td style={{backgroundColor:"transparent" , color:"black", border:"none"}}>{data.move_from_outside_your_home}</td>
                  <td className="fw-normal" style={{backgroundColor:"transparent" , color:"black", border:"none"}}>{data.number_of_pot_plants}</td>
                </tr>
              )}
            </tbody>
          </table>

      </div>
    </>
  );
}

export default QuickInvent;
