import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/HomePage.css";
import LocalMoves from "../../Assests/local_moves.png";
import country from "../../Assests/country.png";
import interstate from "../../Assests/street.png";
import office from "../../Assests/office.png";
import manager from "../../Assests/manager.png";
import wallclock from "../../Assests/wall-clock.png";
import trash from "../../Assests/trash.png";
import unpacking from "../../Assests/unpacking.png";
import mobile from "../../Assests/mobile.png";
import { Link } from "react-router-dom";

const ServiceCard = ({ icon, title, description }) => (
  <div className="custom-service-card">
    <div className="custom-icon mb-3">
      <img src={icon} alt={title} className="img-fluid" />
    </div>
    <h5 className="custom-title mb-3">{title}</h5>
    <p className="custom-description">{description}</p>
  </div>
);

export default function HomePage() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="container my-5">
        <h2 className="text-center ">Full Service Moving</h2>
        <div className="row">
          <div className="col-md-4">
            <ServiceCard
              icon={office}
              title="Office Moves"
              description="We understand how critical a smooth move is for your business."
            />
          </div>
          <div className="col-md-4">
            <ServiceCard
              icon={manager}
              title="Labour Only & Special Projects"
              description="Unique move? Let us customize a package for you. We're here to help."
            />
          </div>
          <div className="col-md-4">
            <ServiceCard
              icon={wallclock}
              title="Last Minute Moves"
              description="Need an urgent move? Call +03 8907 0486  now for fast, affordable service."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <ServiceCard
              icon={LocalMoves}
              title="Local Moves"
              description="Choose expert, hand-picked removalists for any move, from a small apartment to a large home, at your convenience."
            />
          </div>
          <div className="col-md-4">
            <ServiceCard
              icon={unpacking}
              title="Packing & Unpacking"
              description="Relax with our professional packers. We provide all materials (boxes, bubble wrap, etc.). Contact us today."
            />
          </div>
          <div className="col-md-4">
            <ServiceCard
              icon={country}
              title="Country Moves"
              description="Moving to or from rural areas? We serve within a four-hour radius of Sydney & Melbourne. Contact us today."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <ServiceCard
              icon={trash}
              title="Rubbish Removal"
              description="Got excess rubbish? Start by visiting your nearest tip. We’ll add the tip fees to your job—simple and easy!"
            />
          </div>

          <div className="col-md-4">
            <ServiceCard
              icon={mobile}
              title="Utility Connections"
              description="Make your move simple and save on gas and electricity with our free utility connection."
            />
          </div>
          <div className="col-md-4">
            <ServiceCard
              icon={interstate}
              title="Interstate Moves"
              description="From arrival times to storage, we handle it all with transparent pricing and dedicated coordinators."
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <Link to="/" className="Service_pricing" onClick={scrollToTop}>
            Get Instant Pricing
          </Link>
          <a href="tel:+03 8907 0486 " className="Service_call">
            +03 8907 0486
          </a>
        </div>
      </div>

      {/* ================================== */}
      <div className="container my-5">
        <h2 className="text-center mb-5">
          Melbourne's Most Trusted Furniture Removal Company
        </h2>

        <div className="row justify-content-center">
          <div className="col-md-3">
            <ul className="">
              <li>
                <Link
                  to="/narrewarren"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Narre Warren
                </Link>
              </li>
              <li>
                <Link
                  to="/berwick"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Berwick
                </Link>
              </li>
              <li>
                <Link
                  to="/pakenham"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Pakenham
                </Link>
              </li>
              <li>
                <Link
                  to="/cranbourne"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Cranbourne
                </Link>
              </li>
              <li>
                <Link
                  to="/hawthorn"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Hawthorn
                </Link>
              </li>
              <li>
                <Link
                  to="/keysborough"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Keysborough
                </Link>
              </li>
              <li>
                <Link
                  to="/templestowe"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Templestowe
                </Link>
              </li>
              <li>
                <Link
                  to="/boxhill"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Box Hill
                </Link>
              </li>
              <li>
                <Link
                  to="/croydon"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Croydon
                </Link>
              </li>
              <li>
                <Link
                  to="/balwyn "
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Balwyn
                </Link>
              </li>
              <li>
                <Link
                  to="/doncaster"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Doncaster
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-3">
            <ul className="">
              <li>
                <Link
                  to="/oakleigh"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Oakleigh
                </Link>
              </li>
              <li>
                <Link
                  to="/clayton"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Clayton
                </Link>
              </li>
              <li>
                <Link
                  to="/boronia"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Boronia
                </Link>
              </li>
              <li>
                <Link
                  to="/glenwaverley"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Glen Waverley
                </Link>
              </li>
              <li>
                <Link
                  to="/mountwaverley"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Mount Waverley
                </Link>
              </li>
              <li>
                <Link
                  to="/frankston"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Frankston
                </Link>
              </li>
              <li>
                <Link
                  to="/caulfield"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Caulfield
                </Link>
              </li>
              <li>
                <Link
                  to="/mornington"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Mornington
                </Link>
              </li>
              <li>
                <Link
                  to="/seaford"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Seaford
                </Link>
              </li>
              <li>
                <Link
                  to="/essendon"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Essendon
                </Link>
              </li>
              <li>
                <Link
                  to="/frankstonsouth"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Frankston South
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-3">
            <ul className="">
              <li>
                <Link
                  to="/armadale"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Armadale
                </Link>
              </li>
              <li>
                <Link
                  to="/burwood"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Burwood
                </Link>
              </li>
              <li>
                <Link
                  to="/southmelbourne"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  South Melbourne
                </Link>
              </li>
              <li>
                <Link
                  to="/bentleigh"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Bentleigh
                </Link>
              </li>
              <li>
                <Link
                  to="/easternsuburbs"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Eastern Suburbs
                </Link>
              </li>
              <li>
                <Link
                  to="/mordialloc"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Mordialloc
                </Link>
              </li>
              <li>
                <Link
                  to="/ringwood"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Ringwood
                </Link>
              </li>
              <li>
                <Link
                  to="/camberwell"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Camberwell
                </Link>
              </li>
              <li>
                <Link
                  to="/bayside"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Bayside
                </Link>
              </li>
              <li>
                <Link
                  to="/brighton"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Brighton
                </Link>
              </li>
              <li>
                <Link
                  to="/rowville"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Rowville
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
