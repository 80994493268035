import React, { useEffect, useState } from "react";
import "../Details/Details.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FallingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Details() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [userExists, setUserExists] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showNextButton, setShowNextButton] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required.";
    }
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.mobile) {
      newErrors.mobile = "Contact phone is required.";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = "Please enter a valid 10-digit mobile number.";
    }
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      setEmailExistsError("");
    }

    if (name === "email" && value) {
      fetchUserDetails(value);
    }
  };

  const fetchUserDetails = async (email) => {
    try {
      const response = await axios.get(
        "https://booking.skilledmovers.com.au/api/user--data"
      );
      const users = response.data;

      const matchingUser = users.find(
        (user) => user.email.toLowerCase() === email.toLowerCase()
      );

      if (matchingUser) {
        setUserExists(true);
        setUserDetails(matchingUser);
        setEmailExistsError("This email is already registered.");
        setShowNextButton(true);
      } else {
        setUserExists(false);
        setUserDetails(null);
        setShowNextButton(true);
      }
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);

    try {
      const enquiry_id = localStorage.getItem("enquiry_id");
      if (!enquiry_id) {
        console.log("Enquiry ID is missing.");
        setLoading(false);
        return;
      }

      const response = await fetch(
        "https://booking.skilledmovers.com.au/api/post-customer-details",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            enquiry_id: enquiry_id,
            name: formData.name,
            email: formData.email,
            mobile: formData.mobile,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        const { session_token, name, estimate_type, id, email, mobile } =
          result;

        localStorage.setItem("session_token", session_token);
        localStorage.setItem("enquiry_id", enquiry_id);
        localStorage.setItem("token", session_token);
        localStorage.setItem("name", name);
        localStorage.setItem("email", email);
        localStorage.setItem("phone", mobile);
        localStorage.setItem("customer_id", id);

        const estimateType = parseInt(estimate_type, 10);

        switch (estimateType) {
          case 1:
            navigate("/inventorymyquote");
            window.location.reload();
            break;
          case 2:
            navigate("/quickmyquote");
            window.location.reload();
            break;
          case 3:
            navigate("/handlfullmyquote");
            window.location.reload();
            break;
          default:
            console.log("Unknown estimate type returned from the server.");
            break;
        }
      } else {
        console.log(
          result.message || "An error occurred while submitting the form."
        );
      }
    } catch (error) {
      console.log("Network error, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const sessionToken = localStorage.getItem("session_token");
    if (sessionToken) {
      const name = localStorage.getItem("name") || "";
      const email = localStorage.getItem("email") || "";
      const phone = localStorage.getItem("phone") || "";

      setFormData({ name, email, mobile: phone });
      setShowNextButton(true);
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Enable button only if all required fields are filled
    if (formData.name && formData.email && formData.mobile) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [formData]);

  return (
    <>
      <div className="access-properties">
        <div className="container">
          <div
            className="nav d-flex justify-content-around"
            role="tablist"
            aria-orientation="vertical"
          >
            <a className="nav-link">Location</a>
            <a className="nav-link">Move Size</a>
            <a className="nav-link">Date & Time</a>
            <a className="nav-link active">Details</a>
            <a className="nav-link">My Quote</a>
          </div>
          <div className="mobile-navigation">
            <span className="tab-label">Details</span>
          </div>
          <h3 className="text-center heading__text__skilled">
            Where would you like us to send your instant quote?
          </h3>
          <p className="text-center">
            We'll send your quote via email and SMS.
          </p>
          <div className="quote-container" style={{ paddingBottom: "2%" }}>
            <form className="quote-form" onSubmit={handleSubmit}>
              <div className="quote-form-group">
                <label className="quote-form-label" htmlFor="name">
                  <i className="fas fa-user details__logo"></i> Name:
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="quote-form-input w-100"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter Name"
                  required
                />
                {errors.name && <p className="text-danger">{errors.name}</p>}
              </div>

              <div className="quote-form-group">
                <label className="quote-form-label" htmlFor="email">
                  <i className="fas fa-envelope details__logo"></i> Email:
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="quote-form-input w-100"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter Email Id"
                  required
                />
                {errors.email && <p className="text-danger">{errors.email}</p>}
                {/* {emailExistsError && (
                  // <p className="text-danger">{emailExistsError}</p>
                )} */}
              </div>

              <div className="quote-form-group">
                <label className="quote-form-label" htmlFor="phone">
                  <i className="fas fa-phone details__logo"></i> Contact phone:
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="quote-form-input w-100"
                  name="mobile"
                  type="tel"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  placeholder="Enter Mobile Number"
                  required
                />
                {errors.mobile && (
                  <p className="text-danger">{errors.mobile}</p>
                )}
              </div>

              <div className="form-group d-flex justify-content-center ">
                {showNextButton ? (
                  <button
                    className={`next_link ${buttonDisabled ? "disabled" : ""}`}
                    disabled={buttonDisabled}
                    type="submit"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className={`next_link ${buttonDisabled ? "disabled" : ""}`}
                    disabled={buttonDisabled}
                    type="submit"
                  >
                    Next
                  </button>
                )}
              </div>
              {loading && (
                <div className="loading-overlay">
                  <div className="loading-content">
                    <FallingLines
                      color="white"
                      width="50"
                      visible={true}
                      ariaLabel="falling-lines-loading"
                    />
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
