import React, { useState, useEffect } from "react";
import "./Location.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import MovingLocation from "../MovingLocation/MovingLocation";
import Form from "../Date&Time/Date&Time";
import QuoteForm from "../Details/Details";
import Myquote from "../Myquote/Myquote";
import Dropoff from "./Dropoff";
import { FallingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import GoogleImage from "../Myquote/GoogleImage";
import GoogleToImage from "../Myquote/GoogleToImage";

const AccessProperties = ({ type }) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [propertyType, setPropertyType] = useState("");
  const [showUnitDetails, setShowUnitDetails] = useState(false);
  const [showStorageDetails, setShowStorageDetails] = useState(false);
  const [showCommercialDetails, setShowCommercialDetails] = useState(false);
  const [showStairsDetails, setShowStairsDetails] = useState(false);
  const [showLiftBookingMessage, setShowLiftBookingMessage] = useState(false);
  const [selectedCommercialAccess, setSelectedCommercialAccess] = useState("");
  const [stairsCount, setStairsCount] = useState("");
  const [parking, setParking] = useState("");
  const [roomsLevel, setRoomsLevel] = useState("");
  const [levels, setLevels] = useState("");
  const [walkingDistance, setWalkingDistance] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [selectedCommercialType, setSelectedCommercialType] = useState("");
  const [unitAccess, setUnitAccess] = useState("");
  const [storageCompanyName, setStorageCompanyName] = useState("");

  // State for storage unit number
  const [storageUnitNumber, setStorageUnitNumber] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState("");
  const [pickupParking, setPickupParking] = useState("");
  // State for street distance
  const [streetDistance, setStreetDistance] = useState("");
  // State to show/hide additional questions
  const [showStreetDistance, setShowStreetDistance] = useState(false);
  const [dShowStairsDetailsDropOff, setDShowStairsDetailsDropOff] =
    useState(false);

  const updateParking = (value) => {
    setPickupParking(value);
    // console.log("Selected parking type:", value);

    setShowStreetDistance(value === "street");
  };

  const [location, setLocation] = useState();
  const [location2, setLocation2] = useState();

  const containerStyle = {
    width: "100%",
    height: "160px",
    position: "relative",
  };

  const iconStyle = {
    position: "absolute",
    color: "red",
    fontSize: "30px",
    transform: "translate(-50%, -100%)",
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []);

  useEffect(() => {
    const fromLocation = localStorage.getItem("from_location");
    const toLocation = localStorage.getItem("to_location");

    if (fromLocation) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          fromLocation
        )}&key=AIzaSyAtUa8t-LE_sdMtpNgvfBSbfE2Sil2cVvw`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setLocation({ lat, lng });
          }
        })
        .catch((error) =>
          console.error("Error fetching from_location:", error)
        );
    }

    if (toLocation) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          toLocation
        )}&key=AIzaSyAtUa8t-LE_sdMtpNgvfBSbfE2Sil2cVvw`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setLocation2({ lat, lng });
          }
        })
        .catch((error) => console.error("Error fetching to_location:", error));
    }
  }, []);

  // Update street distance
  const handleStreetDistanceChange = (event) => {
    setStreetDistance(event.target.value);
    // console.log(
    //   "Selected street distance (streetDistance):",
    //   event.target.value
    // );
  };
  const quoteId = localStorage.getItem("enquiry_id");

  const handlePostData = async () => {
    // Construct the payload

    const payload = {
      pick_up_property_type: propertyType,
      number_of_levels_in_house: levels,
      how_many_flights_of_stairs: stairsCount,
      sameRoomsInUnit: roomsLevel,
      stairs_or_lift: unitAccess,
      pickupParking: parking,
      pickupdistanceParking: streetDistance,
      storage_company_name: storageCompanyName,
      storage_unit_number: storageUnitNumber,
      walking_distance_from_our_truck_to_your_storage_unit: walkingDistance,
      commercial_property_type: selectedCommercialType,
      pickUpComments: comments,
      enquiry_id: quoteId,
    };

    // Log the payload to the console
    // console.log("Posting Data:", payload);

    try {
      const response = await fetch(
        "https://booking.skilledmovers.com.au/api/enquiry-access-properties-pick-up",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();
      // console.log("Response:", result);

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handlePropertyTypeChange = (type) => {
    setPropertyType(type);
    setShowUnitDetails(type === "unit" || type === "apartment");
    setShowStorageDetails(type === "storage");
    setShowCommercialDetails(type === "commercial");
    setShowStairsDetails(false);
    setShowLiftBookingMessage(false);
    setShowStreetDistance(false);
  };

  // Handle street distance change

  const updateUnitAccess = (value) => {
    setUnitAccess(value);
    // console.log("Selected unit access:", value); // Log the selected value

    // Additional conditions
    if (value === "stairs") {
      setShowStairsDetails(true);
      setShowLiftBookingMessage(false);
    } else if (value === "lift") {
      setShowStairsDetails(false);
      setShowLiftBookingMessage(true);
    } else {
      setShowStairsDetails(false);
      setShowLiftBookingMessage(false);
    }
  };

  // Handle storage company name change
  const handleStorageCompanyNameChange = (event) => {
    const value = event.target.value;
    setStorageCompanyName(value);
    // console.log("Storage company name:", value); // Log the value
  };

  // Handle storage unit number change
  const handleStorageUnitNumberChange = (event) => {
    const value = event.target.value;
    setStorageUnitNumber(value);
    // console.log("Storage unit number:", value); // Log the value
  };

  const updateStairsCount = (value) => {
    setStairsCount(value);
    // console.log("Selected stairs count:", value); // Log the selected value
  };

  const updateRoomsLevel = (value) => {
    setRoomsLevel(value);
    // console.log("Selected rooms level:", value); // Log the selected value
  };
  const handleParkingChange = (parkingType) => {
    setShowStreetDistance(parkingType === "street");
  };

  const updateLevels = (value) => {
    setLevels(value);
    // console.log("Selected levels:", value); // Log the selected value
  };
  const updateWalkingDistance = (value) => {
    setWalkingDistance(value);
    // console.log("Selected walking distance:", value); // Log the selected value
  };
  const handleCommercialTypeChange = (event) => {
    const value = event.target.value;
    setSelectedCommercialType(value);
    // console.log("Selected commercial type:", value); // Log the selected value
  };
  const handleCommentsChange = (event) => {
    const value = event.target.value;
    setComments(value);
    // console.log("Comments:", value); // Log the textarea value
  };

  const getLevelsLabel = () => {
    switch (propertyType) {
      case "townhouse":
        return "Number of levels in this townhouse?";
      case "unit":
        return "Are stairs or a lift required to reach your unit?";
      case "apartment":
        return "Are stairs or a lift required to reach your apartment?";
      case "commercial":
        return "Are stairs or a lift required to reach your commercial property?";
      default:
        return "Number of levels in this house?";
    }
  };

  useEffect(() => {
    // Enable the button if propertyType is not empty
    setIsButtonDisabled(!propertyType);
  }, [propertyType]);

  // Drop off
  const [propertyTypeDropOff, setPropertyTypeDropOff] = useState("");
  const [showUnitDetailsDropOff, setShowUnitDetailsDropOff] = useState(false);
  const [showStorageDetailsDropOff, setShowStorageDetailsDropOff] =
    useState(false);
  const [showCommercialDetailsDropOff, setShowCommercialDetailsDropOff] =
    useState(false);
  const [showStairsDetailsDropOff, setShowStairsDetailsDropOff] =
    useState(false);
  const [showLiftBookingMessageDropOff, setShowLiftBookingMessageDropOff] =
    useState(false);
  const [showStreetDistanceDropOff, setShowStreetDistanceDropOff] =
    useState(false);

  const [selectedCommercialTypeDropOff, setSelectedCommercialTypeDropOff] =
    useState("");
  const [selectedCommercialAccessDropOff, setSelectedCommercialAccessDropOff] =
    useState("");

  const [dropOffUnitAccess, setDropOffUnitAccess] = useState("");
  const [dstairCount, setDstairCount] = useState("");
  const [dtoRoomsLevel, setDtoRoomsLevel] = useState("");
  const [dStorageCompanyName, setDStorageCompanyName] = useState("");
  const [dStorageUnitNumber, setDStorageUnitNumber] = useState("");
  const [dWalkingDistance, setDWalkingDistance] = useState("");
  const [dStorageAccess, setDStorageAccess] = useState("");
  const [dLevels, setDLevels] = useState("");

  const [dComments, setDComments] = useState("");
  const [dDropOffParking, setDDropOffParking] = useState("");
  const [dStreetDistance, setDStreetDistance] = useState("");
  const [dSelectedCommercialTypeDropOff, setDSelectedCommercialTypeDropOff] =
    useState("");
  const [dunitAccess, setdUnitAccess] = useState("");
  const [dstairsCount, setdStairsCount] = useState("");
  const [dshowStairsDetails, setdShowStairsDetails] = useState(false);
  const [dshowLiftBookingMessage, setdShowLiftBookingMessage] = useState(false);
  const [dStairsCount, setDStairsCount] = useState("");

  // Handle change in stairs count selection
  const handleDStairsCountChange = (event) => {
    setDStairsCount(event.target.value);
    // console.log(
    //   "Selected stairs count for drop-off (dStairsCount):",
    //   event.target.value
    // ); // Log the selected value
  };

  // Example function to handle a change in storage access (this is just an example, adjust as needed)
  const handleDStorageAccessChange = (event) => {
    const value = event.target.value;
    setDShowStairsDetailsDropOff(value === "stairsStorage");
    // console.log("Selected storage access (dStorageAccess):", value); // Log the selected value
  };
  // Handler functions
  const dupdateUnitAccess = (accessType) => {
    setdUnitAccess(accessType);
    setdShowStairsDetails(accessType === "stairs");
    setdShowLiftBookingMessage(accessType === "lift");
  };

  const dupdateStairsCount = (count) => {
    setdStairsCount(count);
    // console.log("Selected storage access (dStairsCount):", count);
  };
  // Handle change in commercial type selection
  const handleDCommercialTypeDropOffChange = (event) => {
    setDSelectedCommercialTypeDropOff(event.target.value);
    // console.log(
    //   "Selected commercial type for drop-off (dSelectedCommercialTypeDropOff):",
    //   event.target.value
    // ); // Log the selected value
  };
  // Handle drop-off parking change
  const handleDDropOffParkingChange = (value) => {
    // Update the parking state

    setDDropOffParking(value);
    // console.log("Selected drop-off parking (dDropOffParking):", value); // Log the selected value

    // Show or hide the street distance options based on the selected parking value
    setShowStreetDistanceDropOff(value === "street");
  };

  // Handle street distance change

  const handlePostDropOffData = async () => {
    // Construct the payload for drop-off
    const payload = {
      drop_off_property_type: propertyTypeDropOff,
      drop_off_number_of_levels_in_house: dtoRoomsLevel,
      drop_of_how_many_flights_of_stairs: dStairsCount,
      drop_of_sameRoomsInUnit: dtoRoomsLevel,
      drop_offstairs_or_lift: dropOffUnitAccess,
      drop_off_Parking: dDropOffParking,
      dropoffdistanceParking: dStreetDistance,
      drop_offstorage_company_name: dStorageCompanyName,
      drop_offstorage_unit_number: dStorageUnitNumber,
      drop_offwalking_distance_from_our_truck_to_your_storage_unit:
        dWalkingDistance,
      drop_offcommercial_property_type: selectedCommercialTypeDropOff,
      drop_offComments: dComments,
      enquiry_id: quoteId, // or use a variable if dynamic
    };

    // Log the payload to the console
    // console.log("Posting Drop-Off Data:", payload);

    try {
      const response = await fetch(
        "https://booking.skilledmovers.com.au/api/enquiry-access-properties-drop-off",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();
      // console.log("Drop-Off Response:", result);

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Drop-Off Error:", error);
    }
  };

  // Handle comments change
  const handleDCommentsChange = (event) => {
    setDComments(event.target.value);
    // console.log("Comments (dComments):", event.target.value); // Log the current value
  };
  // Handle street distance change
  const handleDStreetDistanceChange = (value) => {
    setDStreetDistance(value);
    // console.log("Selected street distance (dStreetDistance):", value); // Log the selected value
  };
  // Handle drop-off parking change

  // Handle levels change
  const handleDLevelsChange = (event) => {
    const value = event.target.id; // Use `id` to differentiate the radio buttons
    setDLevels(value);
    // console.log("Selected levels (dLevels):", value); // Log the selected value
  };

  const handleDWalkingDistanceChange = (event) => {
    const value = event.target.id; // Use `id` to differentiate the radio buttons
    setDWalkingDistance(value);
    // console.log("Selected walking distance (dWalkingDistance):", value); // Log the selected value
  };
  // Handle storage company name change
  const handleDStorageCompanyNameChange = (event) => {
    setDStorageCompanyName(event.target.value);
    // console.log(
    //   "Storage company name (dStorageCompanyName):",
    //   event.target.value
    // );
  };

  // Handle storage unit number change
  const handleDStorageUnitNumberChange = (event) => {
    setDStorageUnitNumber(event.target.value);
    // console.log(
    //   "Storage unit number (dStorageUnitNumber):",
    //   event.target.value
    // );
  };
  // Handle rooms level change
  const handleDtoRoomsLevelChange = (event) => {
    const value = event.target.id; // Use `id` to differentiate the radio buttons
    setDtoRoomsLevel(value);
    // console.log("Selected rooms level (dtoRoomsLevel):", value); // Log the selected value
  };

  const handlePropertyTypeChangeDropOff = (type) => {
    setPropertyTypeDropOff(type);
    setShowUnitDetailsDropOff(type === "unit" || type === "apartment");
    setShowStorageDetailsDropOff(type === "storage");
    setShowCommercialDetailsDropOff(type === "commercial");
    setShowStairsDetailsDropOff(false);
    setShowLiftBookingMessageDropOff(false);
    setShowStreetDistanceDropOff(false);
  };
  // Handle stairs count change
  const handleDstairCountChange = (event) => {
    const value = event.target.id; // Use `id` to differentiate the radio buttons
    setDstairCount(value);
    // console.log("Selected stairs count (dstairCount):", value); // Log the selected value
  };

  // Handle unit access change for drop-off
  const handleUnitAccessChangeDropOff = (value) => {
    setDropOffUnitAccess(value);
    // console.log("Selected drop-off unit access:", value); // Log the selected value

    // Optional: Add conditions based on the selected value
    if (value === "stairs") {
      // Set state or perform actions related to stairs
    } else if (value === "lift") {
      // Set state or perform actions related to lift
    } else {
      // Handle other cases
    }
  };

  const handleDropOffParkingChange = (parkingType) => {
    setShowStreetDistanceDropOff(parkingType === "street");
  };

  const getLevelsLabelDropOff = () => {
    switch (propertyTypeDropOff) {
      case "unit":
        return "Are stairs or a lift required to reach your unit?";
      case "apartment":
        return "Are stairs or a lift required to reach your apartment?";
      case "commercial":
        return "Are stairs or a lift required to reach your commercial property?";
      default:
        return "Number of levels in this property?";
    }
  };
  const navigate = useNavigate();
  const showAlert = (message) => {};

  const nextTab = () => {
    if (!propertyType || !propertyTypeDropOff) {
      showAlert("Please fill in the required fields before submitting.");
      return;
    }

    handlePostData();
    handlePostDropOffData();
    navigate("/movinglocation");
  };

  const prevTab = () => {
    const tabOrder = ["tab1", "tab2", "tab3", "tab4", "tab5"];
    const currentIndex = tabOrder.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabOrder[currentIndex - 1]);
    }
  };

  const getTabLabel = () => {
    switch (activeTab) {
      case "tab1":
        return "Location";
      case "tab2":
        return "Move Size";
      case "tab3":
        return "Date & Time";
      case "tab4":
        return "Details";
      case "tab5":
        return "My Quote";
      default:
        return "";
    }
  };

  const viewQuote = () => {
    setIsLoading(true);
    setTimeout(() => {
      setActiveTab("tab5");
      setIsLoading(false);
    }, 3000);
  };

  useEffect(() => {
    setShowCommercialDetails(type === "commercial");
  }, [type]);

  // const handleCommercialTypeChange = (e) => {
  //   setSelectedCommercialType(e.target.value);
  // };

  const handleCommercialAccessChange = (e) => {
    setSelectedCommercialAccess(e.target.value);
  };

  useEffect(() => {
    setShowCommercialDetailsDropOff(type === "commercial");
  }, [type]);

  const handleCommercialTypeDropOffChange = (e) => {
    setSelectedCommercialTypeDropOff(e.target.value);
  };

  const handleCommercialAccessDropOffChange = (e) => {
    setSelectedCommercialAccessDropOff(e.target.value);
  };

  const retrievedFromLocation = localStorage.getItem("from_location");
  const retrievedToLocation = localStorage.getItem("to_location");

  // Define max length for the display
  const maxLength = 35;

  // Slice the strings and add '...' if needed
  const shortretrievedToLocation =
    retrievedToLocation && retrievedToLocation.length > maxLength
      ? `${retrievedToLocation.slice(0, maxLength)}...`
      : retrievedToLocation || "..";

  const shortretrievedFromLocation =
    retrievedFromLocation && retrievedFromLocation.length > maxLength
      ? `${retrievedFromLocation.slice(0, maxLength)}...`
      : retrievedFromLocation || "..";
  return (
    <>
      <div className="access-properties p-2 p-md-5">
        <div className="container locations_tabbs">
          <div className="">
            <div
              className="nav d-flex justify-content-around"
              role="tablist"
              aria-orientation="vertical"
            >
              <a className="nav-link active">Location</a>
              <a className="nav-link">Move Size</a>
              <a className="nav-link">Date & Time</a>
              <a className="nav-link">Details</a>
              <a className="nav-link">My Quote</a>
            </div>
            {/* <div className="tab-content">
              {activeTab === "tab1" && (
                <div className="tab-pane">Content for Location</div>
              )}
              {activeTab === "tab2" && (
                <div className="tab-pane">Content for Move Size</div>
              )}
              {activeTab === "tab3" && (
                <div className="tab-pane">Content for Date & Time</div>
              )}
              {activeTab === "tab4" && (
                <div className="tab-pane">Content for Details</div>
              )}
              {activeTab === "tab5" && (
                <div className="tab-pane">Content for My Quote</div>
              )}
            </div> */}

            <div className="mobile-navigation">
              {/* <button onClick={prevTab} disabled={activeTab === "tab1"}>
                &lt;
              </button> */}
              <span className="tab-label">{getTabLabel()}</span>
              {/* <button onClick={nextTab} disabled={activeTab === "tab5"}>
                &gt;
              </button> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="tab-content">
                {activeTab === "tab1" && (
                  <div className="tab-pane active">
                    <h3 className="text-center heading__text__skilled">
                      How will we gain access to the properties?
                    </h3>
                    <h6 className="text-center">
                      This allows us to estimate the time.
                    </h6>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-lg-6 mb-3">
                        <div className="border__content">
                          <h5 className="pb-3 heading__text__skilled">
                            Pick Up
                          </h5>
                          <h6 className="fs-5 heading__text__skilled">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              style={{ marginRight: "5px" }}
                            />
                            {shortretrievedFromLocation}
                          </h6>

                          <div className="row">
                            <div className="col-md-6">
                              {location && (
                                <GoogleMap
                                  mapContainerStyle={containerStyle}
                                  center={location}
                                  zoom={15}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: `${location.lat}%`,
                                      left: `${location.lng}%`,
                                      transform: "translate(-50%, -50%)",
                                      zIndex: 1,
                                    }}
                                  >
                                    <Marker
                                      position={location}
                                      icon={faMapMarkerAlt}
                                      style={iconStyle}
                                    />
                                  </div>
                                </GoogleMap>
                              )}
                            </div>
                            <div className="col-md-6 align-content-center mt-md-0 mt-3">
                              <GoogleImage />
                            </div>
                          </div>

                          <form>
                            <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                              What type of property are we picking up from?
                            </label>
                            <div className="form-group selected_form__group ">
                              <div className="row layout_border radio-container">
                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="house"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChange("house")
                                      }
                                    />
                                    House
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="unit"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChange("unit")
                                      }
                                    />
                                    Unit
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="townhouse"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChange("townhouse")
                                      }
                                    />
                                    Townhouse
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="apartment"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChange("apartment")
                                      }
                                    />
                                    Apartment
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="storage"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChange("storage")
                                      }
                                    />
                                    Storage
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="commercial"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChange("commercial")
                                      }
                                    />
                                    Commercial
                                  </label>
                                </div>
                                <div className="horizontal-line location-hori"></div>
                                <div className="vertical-line"></div>
                              </div>
                            </div>

                            {/* Unit details */}
                            {showUnitDetails && (
                              <>
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    {getLevelsLabel()}
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border">
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="no"
                                            name="unitAccess"
                                            value="no"
                                            checked={unitAccess === "no"} // Control checked state
                                            onChange={() =>
                                              updateUnitAccess("no")
                                            } // Update state and log
                                          />
                                          No
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="stairs"
                                            name="unitAccess"
                                            value="stairs"
                                            checked={unitAccess === "stairs"}
                                            onChange={() =>
                                              updateUnitAccess("stairs")
                                            } // Update state and log
                                          />
                                          Yes - Stairs
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="lift"
                                            name="unitAccess"
                                            value="lift"
                                            checked={unitAccess === "lift"}
                                            onChange={() =>
                                              updateUnitAccess("lift")
                                            } // Update state and log
                                          />
                                          Yes - A Lift
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {showStairsDetails && (
                                  <div>
                                    <label className="fs-5 font-weight-bold pb-2 pb-md-2">
                                      How many flights of stairs?
                                    </label>
                                    <div className="form-group selected_form__group">
                                      <div className="row layout_border">
                                        <div className="col-md-4">
                                          <label className="radio-label">
                                            <input
                                              type="radio"
                                              id="1 Flight"
                                              name="stairsCount"
                                              value="1 Flight"
                                              checked={
                                                stairsCount === "1 Flight"
                                              }
                                              onChange={() =>
                                                updateStairsCount("1 Flight")
                                              }
                                            />
                                            1 Flight
                                          </label>
                                        </div>
                                        <div className="col-md-4">
                                          <label className="radio-label">
                                            <input
                                              type="radio"
                                              id="2 Flight"
                                              name="stairsCount"
                                              value="2 Flight"
                                              checked={
                                                stairsCount === "2 Flight"
                                              }
                                              onChange={() =>
                                                updateStairsCount("2 Flight")
                                              }
                                            />
                                            2 Flight
                                          </label>
                                        </div>
                                        <div className="col-md-4">
                                          <label className="radio-label">
                                            <input
                                              type="radio"
                                              id="3+ Flight"
                                              name="stairsCount"
                                              value="3+ Flight"
                                              checked={
                                                stairsCount === "3+ Flight"
                                              }
                                              onChange={() =>
                                                updateStairsCount("3+ Flight")
                                              }
                                            />
                                            3+ Flight
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {showLiftBookingMessage && (
                                  <div className="alert alert-info mt-3">
                                    Do you have a lift booking? If you have a
                                    lift booking or set time to access your
                                    lift, please tell us in the comments below.
                                  </div>
                                )}
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    Are all the rooms on the same level inside
                                    your unit?
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border radio-container">
                                      <div className="col-md-12">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="sameLevelYes"
                                            name="roomsLevel"
                                            value="sameLevelYes"
                                            checked={
                                              roomsLevel === "sameLevelYes"
                                            }
                                            onChange={() =>
                                              updateRoomsLevel("sameLevelYes")
                                            }
                                          />
                                          Yes, all rooms are on the same level
                                        </label>
                                      </div>
                                      <div className="col-md-12">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="sameLevelNo"
                                            name="roomsLevel"
                                            value="sameLevelNo"
                                            checked={
                                              roomsLevel === "sameLevelNo"
                                            }
                                            onChange={() =>
                                              updateRoomsLevel("sameLevelNo")
                                            }
                                          />
                                          No, there's multiple levels inside my
                                          unit
                                        </label>
                                      </div>
                                      <div className="horizontal-line location-hori"></div>
                                      <div className="vertical-line"></div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {/* Storage details */}
                            {showStorageDetails && (
                              <>
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    Storage company name?
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter storage company name"
                                    value={storageCompanyName}
                                    onChange={handleStorageCompanyNameChange}
                                  />
                                </div>

                                {/* Storage unit number input */}
                                <div>
                                  <label className="fs-5 mt-2 mt-md-2 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    Storage unit number (if known)?
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter storage unit number"
                                    value={storageUnitNumber}
                                    onChange={handleStorageUnitNumberChange}
                                  />
                                </div>
                                <div>
                                  <label className="fs-5 mt-2 mt-md-2 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    What’s the walking distance from our truck
                                    to your storage unit?
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border">
                                      <div className="col-md-12">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="lessThan50m"
                                            name="walkingDistance"
                                            value="lessThan50m"
                                            checked={
                                              walkingDistance === "lessThan50m"
                                            }
                                            onChange={() =>
                                              updateWalkingDistance(
                                                "lessThan50m"
                                              )
                                            }
                                          />
                                          Less than 50 meters
                                        </label>
                                      </div>
                                      <div className="col-md-12">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="between50and100m"
                                            name="walkingDistance"
                                            value="between50and100m"
                                            checked={
                                              walkingDistance ===
                                              "between50and100m"
                                            }
                                            onChange={() =>
                                              updateWalkingDistance(
                                                "between50and100m"
                                              )
                                            }
                                          />
                                          Between 50 and 100 meters
                                        </label>
                                      </div>
                                      <div className="col-md-12">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="moreThan100m"
                                            name="walkingDistance"
                                            value="moreThan100m"
                                            checked={
                                              walkingDistance === "moreThan100m"
                                            }
                                            onChange={() =>
                                              updateWalkingDistance(
                                                "moreThan100m"
                                              )
                                            }
                                          />
                                          More than 100 meters
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    Are stairs or a lift required to reach your
                                    storage unit?
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border">
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="no"
                                            name="unitAccess"
                                            value="no"
                                            checked={unitAccess === "no"} // Control checked state
                                            onChange={() =>
                                              updateUnitAccess("no")
                                            } // Update state and log
                                          />
                                          No
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="stairs"
                                            name="unitAccess"
                                            value="stairs"
                                            checked={unitAccess === "stairs"}
                                            onChange={() =>
                                              updateUnitAccess("stairs")
                                            } // Update state and log
                                          />
                                          Yes - Stairs
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="lift"
                                            name="unitAccess"
                                            value="lift"
                                            checked={unitAccess === "lift"}
                                            onChange={() =>
                                              updateUnitAccess("lift")
                                            } // Update state and log
                                          />
                                          Yes - A Lift
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {/* Commercial details */}
                            {showCommercialDetails && (
                              <>
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    What type of commercial property are we
                                    picking up from?
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border radio-container">
                                      <div className="col-md-6">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="office"
                                            name="commercialType"
                                            value="office"
                                            checked={
                                              selectedCommercialType ===
                                              "office"
                                            }
                                            onChange={
                                              handleCommercialTypeChange
                                            }
                                          />
                                          Office
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="industrial"
                                            name="commercialType"
                                            value="industrial"
                                            checked={
                                              selectedCommercialType ===
                                              "industrial"
                                            }
                                            onChange={
                                              handleCommercialTypeChange
                                            }
                                          />
                                          Industrial
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="shopRetail"
                                            name="commercialType"
                                            value="shopRetail"
                                            checked={
                                              selectedCommercialType ===
                                              "shopRetail"
                                            }
                                            onChange={
                                              handleCommercialTypeChange
                                            }
                                          />
                                          Shop / Retail
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="other"
                                            name="commercialType"
                                            value="other"
                                            checked={
                                              selectedCommercialType === "other"
                                            }
                                            onChange={
                                              handleCommercialTypeChange
                                            }
                                          />
                                          Other
                                        </label>
                                      </div>
                                      <div className="horizontal-line location-hori"></div>
                                      <div className="vertical-line"></div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    Are stairs or a lift required to reach your
                                    commercial property?
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border">
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="no"
                                            name="unitAccess"
                                            value="no"
                                            checked={unitAccess === "no"} // Control checked state
                                            onChange={() =>
                                              updateUnitAccess("no")
                                            } // Update state and log
                                          />
                                          No
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="stairs"
                                            name="unitAccess"
                                            value="stairs"
                                            checked={unitAccess === "stairs"}
                                            onChange={() =>
                                              updateUnitAccess("stairs")
                                            } // Update state and log
                                          />
                                          Yes - Stairs
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="lift"
                                            name="unitAccess"
                                            value="lift"
                                            checked={unitAccess === "lift"}
                                            onChange={() =>
                                              updateUnitAccess("lift")
                                            } // Update state and log
                                          />
                                          Yes - A Lift
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {/* Number of levels section */}
                            {!showUnitDetails &&
                              !showStorageDetails &&
                              !showCommercialDetails && (
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    {getLevelsLabel()}
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border">
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="single"
                                            name="levels"
                                            value="single"
                                            checked={levels === "single"}
                                            onChange={() =>
                                              updateLevels("single")
                                            }
                                          />
                                          Single
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="double"
                                            name="levels"
                                            value="double"
                                            checked={levels === "double"}
                                            onChange={() =>
                                              updateLevels("double")
                                            }
                                          />
                                          Double
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="triple"
                                            name="levels"
                                            value="triple"
                                            checked={levels === "triple"}
                                            onChange={() =>
                                              updateLevels("triple")
                                            }
                                          />
                                          Triple
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                            {/* Common section */}
                            <div>
                              <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                Where will our truck be parked?
                              </label>
                              <div className="form-group selected_form__group">
                                <div className="row layout_border">
                                  <div className="col-md-4">
                                    <label className="radio-label">
                                      <input
                                        type="radio"
                                        id="driveway"
                                        name="parking"
                                        value="driveway"
                                        checked={pickupParking === "driveway"}
                                        onChange={() =>
                                          updateParking("driveway")
                                        }
                                      />
                                      Driveway
                                    </label>
                                  </div>
                                  <div className="col-md-4">
                                    <label className="radio-label">
                                      <input
                                        type="radio"
                                        id="street"
                                        name="parking"
                                        value="street"
                                        checked={pickupParking === "street"}
                                        onChange={() => updateParking("street")}
                                      />
                                      Street
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Additional question for street parking */}
                            {showStreetDistance && (
                              <div>
                                <label className="fs-5 font-weight-bold heading__text__skilled">
                                  How far is the walk from the street to access
                                  your property?
                                </label>
                                <div className="form-group selected_form__group">
                                  <div className="row layout_border">
                                    <div className="col-md-12">
                                      <label className="radio-label">
                                        <input
                                          type="radio"
                                          id="lessThan20mStreet"
                                          name="streetDistance"
                                          value="lessThan20mStreet"
                                          checked={
                                            streetDistance ===
                                            "lessThan20mStreet"
                                          }
                                          onChange={handleStreetDistanceChange}
                                        />
                                        Less than 20m
                                      </label>
                                    </div>
                                    <div className="col-md-12">
                                      <label className="radio-label">
                                        <input
                                          type="radio"
                                          id="moreThan20mStreet"
                                          name="streetDistance"
                                          value="moreThan20mStreet"
                                          checked={
                                            streetDistance ===
                                            "moreThan20mStreet"
                                          }
                                          onChange={handleStreetDistanceChange}
                                        />
                                        More than 20m
                                      </label>
                                    </div>
                                    <div className="col-md-12">
                                      <label className="radio-label">
                                        <input
                                          type="radio"
                                          id="dontKnowStreet"
                                          name="streetDistance"
                                          value="dontKnowStreet"
                                          checked={
                                            streetDistance === "dontKnowStreet"
                                          }
                                          onChange={handleStreetDistanceChange}
                                        />
                                        I don't know
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <label
                              htmlFor="comments"
                              className="fs-5 font-weight-bold heading__text__skilled"
                            >
                              Comments?
                            </label>
                            <div className="form-group">
                              <p className="fs-5 font-weight-bold heading__text__skilled">
                                Anything we should know about access to this
                                property?
                              </p>
                            </div>
                            <textarea
                              id="comments"
                              className="form-control"
                              value={comments}
                              onChange={handleCommentsChange}
                            ></textarea>
                          </form>
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12 col-lg-6 pt-3 p-md-0 parent">
                        <div className="border__content">
                          <h5 className="pb-3 heading__text__skilled">
                            Drop Off
                          </h5>
                          <h6 className="fs-5 heading__text__skilled">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                            {shortretrievedToLocation}
                          </h6>

                          <div className="row">
                            <div className="col-md-6">
                              {location2 && (
                                <GoogleMap
                                  mapContainerStyle={containerStyle}
                                  center={location2}
                                  zoom={15}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: `${location2.lat}%`,
                                      left: `${location2.lng}%`,
                                      transform: "translate(-50%, -50%)",
                                      zIndex: 1,
                                    }}
                                  >
                                    <Marker
                                      position={location2}
                                      icon={faMapMarkerAlt}
                                      style={iconStyle}
                                    />
                                  </div>
                                </GoogleMap>
                              )}
                            </div>
                            <div className="col-md-6 align-content-center mt-md-0 mt-3">
                              <GoogleToImage />
                            </div>
                          </div>
                          <form>
                            <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                              What type of property are we dropping off at?
                            </label>
                            <div className="form-group selected_form__group">
                              <div className="row layout_border radio-container">
                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="house"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChangeDropOff("house")
                                      }
                                    />
                                    House
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="unit"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChangeDropOff("unit")
                                      }
                                    />
                                    Unit
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="townhouse"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChangeDropOff(
                                          "townhouse"
                                        )
                                      }
                                    />
                                    Townhouse
                                  </label>
                                </div>

                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="apartment"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChangeDropOff(
                                          "apartment"
                                        )
                                      }
                                    />
                                    Apartment
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="storage"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChangeDropOff(
                                          "storage"
                                        )
                                      }
                                    />
                                    Storage
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <label className="radio-label">
                                    <input
                                      type="radio"
                                      id="commercial"
                                      name="propertyType"
                                      onClick={() =>
                                        handlePropertyTypeChangeDropOff(
                                          "commercial"
                                        )
                                      }
                                    />
                                    Commercial
                                  </label>
                                </div>
                                <div className="horizontal-line location-hori"></div>
                                <div className="vertical-line"></div>
                              </div>
                            </div>

                            {showUnitDetailsDropOff && (
                              <>
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    {getLevelsLabelDropOff()}
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border">
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="no"
                                            name="unitAccess"
                                            value="no"
                                            checked={dunitAccess === "no"}
                                            onChange={() =>
                                              dupdateUnitAccess("no")
                                            }
                                          />
                                          No
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="stairs"
                                            name="unitAccess"
                                            value="stairs"
                                            checked={dunitAccess === "stairs"}
                                            onChange={() =>
                                              dupdateUnitAccess("stairs")
                                            }
                                          />
                                          Yes - Stairs
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="lift"
                                            name="unitAccess"
                                            value="lift"
                                            checked={dunitAccess === "lift"}
                                            onChange={() =>
                                              dupdateUnitAccess("lift")
                                            }
                                          />
                                          Yes - A Lift
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {dshowStairsDetails && (
                                  <div>
                                    <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                      How many flights of stairs?
                                    </label>
                                    <div className="form-group selected_form__group">
                                      <div className="row layout_border">
                                        <div className="col-md-4">
                                          <label className="radio-label">
                                            <input
                                              type="radio"
                                              id="1 Flight"
                                              name="stairsCount"
                                              value="1 Flight"
                                              checked={
                                                dstairsCount === "1 Flight"
                                              }
                                              onChange={() =>
                                                dupdateStairsCount("1 Flight")
                                              }
                                            />
                                            1 Flight
                                          </label>
                                        </div>
                                        <div className="col-md-4">
                                          <label className="radio-label">
                                            <input
                                              type="radio"
                                              id="2 Flight"
                                              name="stairsCount"
                                              value="2 Flight"
                                              checked={
                                                dstairsCount === "2 Flight"
                                              }
                                              onChange={() =>
                                                dupdateStairsCount("2 Flight")
                                              }
                                            />
                                            2 Flight
                                          </label>
                                        </div>
                                        <div className="col-md-4">
                                          <label className="radio-label">
                                            <input
                                              type="radio"
                                              id="3+ Flight"
                                              name="stairsCount"
                                              value="3+ Flight"
                                              checked={
                                                dstairsCount === "3+ Flight"
                                              }
                                              onChange={() =>
                                                dupdateStairsCount("3+ Flight")
                                              }
                                            />
                                            3+ Flight
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {dshowLiftBookingMessage && (
                                  <div className="alert alert-info mt-3">
                                    Do you have a lift booking? If you have a
                                    lift booking or set time to access your
                                    lift, please tell us in the comments below.
                                  </div>
                                )}
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    Are all the rooms on the same level inside
                                    your unit?
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border radio-container">
                                      <div className="col-md-12">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="sameLevelYes"
                                            name="roomsLevel"
                                            checked={
                                              dtoRoomsLevel === "sameLevelYes"
                                            }
                                            onChange={handleDtoRoomsLevelChange}
                                          />
                                          Yes, all rooms are on the same level
                                        </label>
                                      </div>
                                      <div className="col-md-12">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="sameLevelNo"
                                            name="roomsLevel"
                                            checked={
                                              dtoRoomsLevel === "sameLevelNo"
                                            }
                                            onChange={handleDtoRoomsLevelChange}
                                          />
                                          No, there's multiple level inside my
                                          unit
                                        </label>
                                      </div>
                                      <div className="horizontal-line location-hori"></div>
                                      <div className="vertical-line"></div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {showStorageDetailsDropOff && (
                              <>
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    Storage company name?
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter storage company name"
                                    value={dStorageCompanyName}
                                    onChange={handleDStorageCompanyNameChange}
                                  />
                                </div>

                                {/* Storage unit number */}
                                <div>
                                  <label className="fs-5 mt-2 mt-md-2 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    Storage unit number (if known)?
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter storage unit number"
                                    value={dStorageUnitNumber}
                                    onChange={handleDStorageUnitNumberChange}
                                  />
                                </div>
                                <div>
                                  <label className="fs-5 mt-2 mt-md-2 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    What’s the walking distance from our truck
                                    to your storage unit?
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border">
                                      <div className="col-md-12">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="lessThan50m"
                                            name="walkingDistance"
                                            checked={
                                              dWalkingDistance === "lessThan50m"
                                            }
                                            onChange={
                                              handleDWalkingDistanceChange
                                            }
                                          />
                                          Less than 50 meters
                                        </label>
                                      </div>
                                      <div className="col-md-12">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="between50and100m"
                                            name="walkingDistance"
                                            checked={
                                              dWalkingDistance ===
                                              "between50and100m"
                                            }
                                            onChange={
                                              handleDWalkingDistanceChange
                                            }
                                          />
                                          Between 50 and 100 meters
                                        </label>
                                      </div>
                                      <div className="col-md-12">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="moreThan100m"
                                            name="walkingDistance"
                                            checked={
                                              dWalkingDistance ===
                                              "moreThan100m"
                                            }
                                            onChange={
                                              handleDWalkingDistanceChange
                                            }
                                          />
                                          More than 100 meters
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    Are stairs or a lift required to reach your
                                    storage unit?
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border">
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="noStorage"
                                            name="storageAccess"
                                            value="noStorage"
                                            onChange={
                                              handleDStorageAccessChange
                                            }
                                          />
                                          No
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="stairsStorage"
                                            name="storageAccess"
                                            value="stairsStorage"
                                            onChange={
                                              handleDStorageAccessChange
                                            }
                                          />
                                          Yes - Stairs
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="liftStorage"
                                            name="storageAccess"
                                            value="liftStorage"
                                            onChange={
                                              handleDStorageAccessChange
                                            }
                                          />
                                          Yes - A Lift
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {dShowStairsDetailsDropOff && (
                                  <div>
                                    <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                      How many flights of stairs?
                                    </label>
                                    <div className="form-group selected_form__group">
                                      <div className="row layout_border">
                                        <div className="col-md-4">
                                          <label className="radio-label">
                                            <input
                                              type="radio"
                                              id="1 Flight"
                                              name="stairsCount"
                                              value="1 Flight"
                                              checked={
                                                dStairsCount === "1 Flight"
                                              }
                                              onChange={
                                                handleDStairsCountChange
                                              }
                                            />
                                            1 Flight
                                          </label>
                                        </div>
                                        <div className="col-md-4">
                                          <label className="radio-label">
                                            <input
                                              type="radio"
                                              id="2 Flight"
                                              name="stairsCount"
                                              value="2 Flight"
                                              checked={
                                                dStairsCount === "2 Flight"
                                              }
                                              onChange={
                                                handleDStairsCountChange
                                              }
                                            />
                                            2 Flight
                                          </label>
                                        </div>
                                        <div className="col-md-4">
                                          <label className="radio-label">
                                            <input
                                              type="radio"
                                              id="3+ Flight"
                                              name="stairsCount"
                                              value="3+ Flight"
                                              checked={
                                                dStairsCount === "3+ Flight"
                                              }
                                              onChange={
                                                handleDStairsCountChange
                                              }
                                            />
                                            3+ Flight
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}

                            {showCommercialDetailsDropOff && (
                              <>
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    What type of commercial property are we
                                    dropping off at?
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border radio-container">
                                      <div className="col-md-6">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="officeDropOff"
                                            name="commercialTypeDropOff"
                                            value="office"
                                            checked={
                                              dSelectedCommercialTypeDropOff ===
                                              "office"
                                            }
                                            onChange={
                                              handleDCommercialTypeDropOffChange
                                            }
                                          />
                                          Office
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="industrialDropOff"
                                            name="commercialTypeDropOff"
                                            value="industrial"
                                            checked={
                                              dSelectedCommercialTypeDropOff ===
                                              "industrial"
                                            }
                                            onChange={
                                              handleDCommercialTypeDropOffChange
                                            }
                                          />
                                          Industrial
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="shopRetailDropOff"
                                            name="commercialTypeDropOff"
                                            value="shopRetail"
                                            checked={
                                              dSelectedCommercialTypeDropOff ===
                                              "shopRetail"
                                            }
                                            onChange={
                                              handleDCommercialTypeDropOffChange
                                            }
                                          />
                                          Shop / Retail
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="otherDropOff"
                                            name="commercialTypeDropOff"
                                            value="other"
                                            checked={
                                              dSelectedCommercialTypeDropOff ===
                                              "other"
                                            }
                                            onChange={
                                              handleDCommercialTypeDropOffChange
                                            }
                                          />
                                          Other
                                        </label>
                                      </div>
                                      <div className="horizontal-line location-hori"></div>
                                      <div className="vertical-line"></div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    Are stairs or a lift required to reach your
                                    commercial property?
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border">
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="noCommercialDropOff"
                                            name="commercialAccessDropOff"
                                            value="no"
                                            checked={
                                              selectedCommercialAccessDropOff ===
                                              "no"
                                            }
                                            onChange={
                                              handleCommercialAccessDropOffChange
                                            }
                                          />
                                          No
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="stairsCommercialDropOff"
                                            name="commercialAccessDropOff"
                                            value="stairs"
                                            checked={
                                              selectedCommercialAccessDropOff ===
                                              "stairs"
                                            }
                                            onChange={
                                              handleCommercialAccessDropOffChange
                                            }
                                          />
                                          Yes - Stairs
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="liftCommercialDropOff"
                                            name="commercialAccessDropOff"
                                            value="lift"
                                            checked={
                                              selectedCommercialAccessDropOff ===
                                              "lift"
                                            }
                                            onChange={
                                              handleCommercialAccessDropOffChange
                                            }
                                          />
                                          Yes - A Lift
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {!showUnitDetailsDropOff &&
                              !showStorageDetailsDropOff &&
                              !showCommercialDetailsDropOff && (
                                <div>
                                  <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                    {getLevelsLabelDropOff()}
                                  </label>
                                  <div className="form-group selected_form__group">
                                    <div className="row layout_border">
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="single"
                                            name="levels"
                                            checked={dLevels === "single"}
                                            onChange={handleDLevelsChange}
                                          />
                                          Single
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="double"
                                            name="levels"
                                            checked={dLevels === "double"}
                                            onChange={handleDLevelsChange}
                                          />
                                          Double
                                        </label>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="radio-label">
                                          <input
                                            type="radio"
                                            id="triple"
                                            name="levels"
                                            checked={dLevels === "triple"}
                                            onChange={handleDLevelsChange}
                                          />
                                          Triple
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                            <div>
                              <label className="fs-5 font-weight-bold pb-2 pb-md-2 heading__text__skilled">
                                Where will our truck be parked?
                              </label>
                              <div className="form-group selected_form__group">
                                <div className="row layout_border">
                                  <div className="col-md-4">
                                    <label className="radio-label">
                                      <input
                                        type="radio"
                                        id="driveway"
                                        name="parking"
                                        checked={dDropOffParking === "driveway"}
                                        onChange={() =>
                                          handleDDropOffParkingChange(
                                            "driveway"
                                          )
                                        }
                                      />
                                      Driveway
                                    </label>
                                  </div>
                                  <div className="col-md-4">
                                    <label className="radio-label">
                                      <input
                                        type="radio"
                                        id="street"
                                        name="parking"
                                        checked={dDropOffParking === "street"}
                                        onChange={() =>
                                          handleDDropOffParkingChange("street")
                                        }
                                      />
                                      Street
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {showStreetDistanceDropOff && (
                              <div>
                                <label className="fs-5 font-weight-bold heading__text__skilled">
                                  How far is the walk from the street to access
                                  your property?
                                </label>
                                <div className="form-group selected_form__group">
                                  <div className="row layout_border">
                                    <div className="col-md-12">
                                      <label className="radio-label">
                                        <input
                                          type="radio"
                                          id="lessThan50mStreet"
                                          name="streetDistance"
                                          checked={
                                            dStreetDistance ===
                                            "lessThan50mStreet"
                                          }
                                          onChange={() =>
                                            handleDStreetDistanceChange(
                                              "lessThan50mStreet"
                                            )
                                          }
                                        />
                                        Less than 20m
                                      </label>
                                    </div>
                                    <div className="col-md-12">
                                      <label className="radio-label">
                                        <input
                                          type="radio"
                                          id="between50and100mStreet"
                                          name="streetDistance"
                                          checked={
                                            dStreetDistance ===
                                            "between50and100mStreet"
                                          }
                                          onChange={() =>
                                            handleDStreetDistanceChange(
                                              "between50and100mStreet"
                                            )
                                          }
                                        />
                                        More than 20m
                                      </label>
                                    </div>
                                    <div className="col-md-12">
                                      <label className="radio-label">
                                        <input
                                          type="radio"
                                          id="moreThan100mStreet"
                                          name="streetDistance"
                                          checked={
                                            dStreetDistance ===
                                            "moreThan100mStreet"
                                          }
                                          onChange={() =>
                                            handleDStreetDistanceChange(
                                              "moreThan100mStreet"
                                            )
                                          }
                                        />
                                        I don't know
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            <label
                              htmlFor="comments"
                              className="fs-5 font-weight-bold heading__text__skilled"
                            >
                              Comments?
                            </label>
                            <div className="form-group">
                              <p className="fs-5 font-weight-bold heading__text__skilled">
                                Anything we should know about access to this
                                property?
                              </p>
                            </div>
                            <textarea
                              id="comments"
                              className="form-control"
                              value={dComments}
                              onChange={handleDCommentsChange}
                            ></textarea>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div className="tab-pane active">
                    <MovingLocation />
                  </div>
                )}
                {activeTab === "tab3" && (
                  <div className="tab-pane active">
                    <Form />
                  </div>
                )}
                {activeTab === "tab4" && (
                  <div className="tab-pane active">
                    <QuoteForm />
                  </div>
                )}
                {activeTab === "tab5" && (
                  <div className="tab-pane active">
                    <Myquote />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isLoading && activeTab !== "tab5" && (
        <div className="bg__footerContainer">
          <div className="footer-container">
            {activeTab === "tab4" ? (
              <button type="button" className="next_link" onClick={viewQuote}>
                View Quote
              </button>
            ) : (
              <>
                {/* <button type="button" className="prev_link" onClick={prevTab}>
                  PREV
                </button> */}
                <button
                  type="button"
                  className="next_link"
                  onClick={nextTab}
                  disabled={isButtonDisabled}
                >
                  NEXT
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <FallingLines color="#fff" height={50} width={50} />
            <p className="loading-text">
              Please wait, your data is being loaded
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AccessProperties;
