import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Nav,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "../Navbar/Navbar.css";
import logo from "../Assests/cargo_truck.png";
import { FaRegUser } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import logos from "../Assests/Skilled-Movers-Logo.png";

export default function NavigationBar() {
  const [expanded, setExpanded] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginStep, setLoginStep] = useState(1);
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({ email: "", name: "", mobile: "" });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const sessionToken = localStorage.getItem("session_token");
    const name = localStorage.getItem("name");

    if (sessionToken && name) {
      setUser({ name, token: sessionToken });
    }
  }, []);

  const handleToggle = () => setExpanded(!expanded);
  const handleClose = () => setExpanded(false);

  const handleShowLoginModal = () => {
    setLoginStep(1);
    setShowLoginModal(true);
  };

  const handleCloseLoginModal = () => setShowLoginModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile" && value.length > 10) return;
    setFormData({ ...formData, [name]: value });

    const updatedErrors = { ...errors };

    if (name === "email") {
      if (value === "") {
        delete updatedErrors.email;
      } else if (!validateEmail(value)) {
        updatedErrors.email = "Invalid email address";
      } else {
        delete updatedErrors.email;
      }
    }

    if (name === "mobile") {
      if (value === "") {
        delete updatedErrors.mobile;
      } else if (!validatePhoneNumber(value)) {
        updatedErrors.mobile = "Invalid mobile number";
      } else {
        delete updatedErrors.mobile;
      }
    }

    setErrors(updatedErrors);
  };

  const handleSendQuoteLink = async () => {
    try {
      const response = await axios.post(
        "https://booking.skilledmovers.com.au/api/login-user",
        {
          email: formData.email,
        }
      );

      if (response.status === 200) {
        const user = response.data.user; // Extract user object from response

        // Store user details in localStorage
        localStorage.setItem("name", user.name);
        localStorage.setItem("email", user.email);
        localStorage.setItem("phone", user.phone);
        localStorage.setItem("session_token", user.session_token);

        setModalContent(
          "Link to view quote will be sent to your Mail ID."
        );
        setShowModal(true);

        // Optionally, redirect or proceed to next step
        window.location.reload();
      } else {
        setLoginStep(2); // Move to step 2 if not registered
      }
    } catch (error) {
      console.error("Error sending quote link", error);

      setLoginStep(2); // Move to step 2 on error
    }
  };

  const handleNextStep = async () => {
    if (loginStep === 1) {
      await handleSendQuoteLink();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        "https://booking.skilledmovers.com.au/api/save-user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            mobile: formData.mobile,
          }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        const { user } = result; // Extract user object from the result

        // Store user details and session token in localStorage
        localStorage.setItem("session_token", user.session_token);
        localStorage.setItem("name", user.name);
        localStorage.setItem("email", user.email);
        localStorage.setItem("phone", user.phone);
        localStorage.setItem("access_id", user.access_id);

        window.location.reload();
      } else {
        setModalContent("Login failed, please try again.");
        setShowModal(true);
      }
    } catch (error) {
      setModalContent("Network error, please try again later.");
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    const errors = {};
    if (loginStep === 2) {
      // Validation for additional fields
      if (!formData.name) errors.name = "Name is required";
      if (!formData.mobile) errors.mobile = "Mobile number is required";
    }
    if (!formData.email) errors.email = "Email is required";
    return errors;
  };

  const truncateName = (name) => {
    return name.length > 7 ? `${name.slice(0, 7)}...` : name;
  };

  useEffect(() => {
    // Scroll to the top on component mount
    window.scrollTo(0, 0);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    // Redirect or reload the page
    window.location.reload();
    window.scrollTo(0, 0);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = (mobile) => {
    const phonePattern = /^[0-9]{10,}$/;
    return phonePattern.test(mobile);
  };

  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        className={`sticky-top ${scrolled ? "scrolled" : ""}`}
        expanded={expanded}
      >
        <Container>
          <Navbar.Brand href="/" className="nav_title">
            <img
              src={logos}
              width="70%"
              className="d-inline-block align-top logo_nav"
              alt="shipping"
            />
            {/* Skilled Mover */}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleToggle}
          >
            <FontAwesomeIcon icon={expanded ? faTimes : faBars} />
          </Navbar.Toggle>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={expanded ? "show" : ""}
          >
            <Nav className="ms-auto">
              <Link
                to="/melbourne-removalists"
                className="nav-link"
                onClick={handleClose}
              >
                Local removalists
              </Link>
              <Link to="/aboutus" className="nav-link" onClick={handleClose}>
                About Us
              </Link>
              {/* <Link
                to="/becomeamover"
                className="nav-link"
                onClick={handleClose}
              >
                Become a Mover
              </Link> */}
              <Link to="/contactus" className="nav-link" onClick={handleClose}>
                Contact Us
              </Link>
              <a
                href="tel:+03 8907 0486"
                className="nav-link"
                onClick={handleClose}
              >
                +03 8907 0486
              </a>
              {user ? (
                <>
                  <button
                    className="nav__links"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => navigate("/profile")}
                  >
                    {truncateName(user.name)}
                    <FaRegUser style={{ marginLeft: "2px", color: "white" }} />
                  </button>
                  <button
                    className="nav__links"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                    <IoIosLogOut
                      style={{ marginLeft: "2px", color: "white" }}
                    />
                  </button>
                </>
              ) : (
                <span
                  className="nav__links"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleClose();
                    handleShowLoginModal();
                  }}
                >
                  Login
                </span>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        show={showLoginModal}
        onHide={handleCloseLoginModal}
        centered
        className="login__form"
      >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <Spinner animation="border" />}
          {loginStep === 1 && (
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Enter your Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  isInvalid={!!errors.email}
                />
                {errors.email && (
                  <Form.Text className="text-danger">{errors.email}</Form.Text>
                )}
              </Form.Group>
              <Button
                type="button"
                className="mt-3 login__form-btn"
                onClick={handleNextStep}
                disabled={!validateEmail(formData.email)}
              >
                Next
              </Button>
            </Form>
          )}
          {loginStep === 2 && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  isInvalid={!!errors.name}
                />
                {errors.name && (
                  <Form.Text className="text-danger">{errors.name}</Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicMobile">
                <Form.Label className="pt-2">Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="Enter your mobile number"
                  isInvalid={!!errors.mobile}
                />
                {errors.mobile && (
                  <Form.Text className="text-danger">{errors.mobile}</Form.Text>
                )}
              </Form.Group>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  type="button"
                  className="mt-3 login__form-btn me-2"
                  onClick={() => setLoginStep(1)}
                >
                  Go back
                </Button>
                <Button
                  className="mt-3 login__form-btn"
                  type="submit"
                  disabled={
                    loading ||
                    !validateEmail(formData.email) ||
                    !formData.name ||
                    !validatePhoneNumber(formData.mobile)
                  }
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body>{modalContent}</Modal.Body>
      </Modal>
    </>
  );
}
